import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  generalSettings: {},
  headerSettings: {},
  footerSettings: {},
  primaryNavigation: [],
  homepageLink: {},
};

const globalSettingsSlice = createSlice({
  name: 'globalSettings',
  initialState,
  reducers: {
    // Give case reducers meaningful past-tense "event"-style names
    globalSettingsSaveHeaderSettings(state, action) {
      state.headerSettings = action.payload;
    },
    globalSettingsSaveFooterSettings(state, action) {
      state.footerSettings = action.payload;
    },
    globalSettingsSaveGeneralSettings(state, action) {
      state.generalSettings = action.payload;
    },
    globalSettingsSavePrimaryNavigation(state, action) {
      state.primaryNavigation = action.payload;
    },
    globalSettingsSaveTopBanner(state, action) {
      state.topBanner = action.payload;
    },
    globalSettingsSaveHomepageLink(state, action) {
      state.homepageLink = action.payload;
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
  globalSettingsSaveHeaderSettings,
  globalSettingsSaveFooterSettings,
  globalSettingsSaveGeneralSettings,
  globalSettingsSavePrimaryNavigation,
  globalSettingsSaveTopBanner,
  globalSettingsSaveHomepageLink,
} = globalSettingsSlice.actions;

// Export the slice reducer as the default export
export default globalSettingsSlice.reducer;
