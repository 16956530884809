// Import React for SVG support.
// See https://github.com/airbnb/babel-plugin-inline-react-svg/issues/31#issuecomment-484333000
import React from 'react'; /* eslint-disable-line no-unused-vars */
import { DEFAULT_COUNTRY_CODE, DONATION_FORM_STEPS, DONATION_TYPES_PATH_ALIAS } from './constants';
import * as field from './transforms.fields';
import { toQuerystring } from '../routing/routes';

export const buildStepPath = (pathPrefix, stepName, tokens = {}) => {
  if (DONATION_FORM_STEPS[stepName] === undefined) {
    return pathPrefix;
  }

  let path = `${pathPrefix}${DONATION_FORM_STEPS[stepName].path}`;
  const query = {};

  if (tokens.type) {
    query.type = DONATION_TYPES_PATH_ALIAS[tokens.type];
  }

  // Append donation type to the first step if it's not found in appeal alias.
  // Examples:
  // 1. demo-appeal -> demo-appeal/checkout/single
  // 2. donate/single -> donate/single/checkout (no suffix because type is represented in an alias).
  if (stepName === 'amount' && tokens.type !== undefined) {
    if (!pathPrefix.endsWith(`/${DONATION_TYPES_PATH_ALIAS[tokens.type]}`)) {
      path = `${path}/${DONATION_TYPES_PATH_ALIAS[tokens.type]}`;
    }
  }

  // Append order id to the last step.
  if (stepName === 'thank-you' && tokens.orderID !== undefined) {
    query.orderID = tokens.orderID;
  }

  const queryString = toQuerystring(query);
  if (queryString) {
    path = `${path}?${queryString}`;
  }

  return path;
};

/**
 * Get donation form URL with fallback to default SD form.
 */
export const getDonationFormUrl = (appeal, query = {}) => {
  const fallback = {
    url: '/donate/single/checkout',
    route: '/node/appeal',
    href: '/node/appeal',
    as: '/donate/single/checkout',
    isExternal: false,
  };

  const url = field.getEntityURL(appeal);
  if (url === null || url.route === null || !url.route.startsWith('/node/appeal')) {
    return fallback;
  }
  const as = buildStepPath(url.as, 'amount', query);
  return {
    url: as,
    route: url.route,
    href: `/node/appeal?${toQuerystring(query)}`,
    as,
    isExternal: false,
  };
};

/**
 * Get page URL.
 */
export const getPageUrl = (page, query = {}) => {
  const url = field.getEntityURL(page);

  return {
    ...url,
    href: `${url.href}?${toQuerystring(query)}`,
  };
};

export const getPreferencesOptions = () => {
  if (DEFAULT_COUNTRY_CODE === 'GB') {
    return [
      {
        value: 'email',
        valueBackend: 'allowed',
        label: 'Email',
        labelON: 'Email',
      },
      {
        value: 'phone',
        valueBackend: 'allowed',
        label: 'Phone',
        labelON: 'Phone',
      },
      {
        value: 'sms',
        valueBackend: 'allowed',
        label: 'SMS',
        labelON: 'SMS',
      },
      {
        value: 'post',
        valueBackend: 'denied',
        label: 'Do NOT contact me by post',
        labelON: 'Do NOT contact me by post',
      },
    ];
  }

  return [
    {
      value: 'email',
      valueBackend: 'allowed',
      label: "I'm happy to hear from you by email",
      labelON: "I'm happy to hear from you by email",
    },
    {
      value: 'phone',
      valueBackend: 'allowed',
      label: "I'm happy to hear from you by phone",
      labelON: "I'm happy to hear from you by phone",
    },
    {
      value: 'sms',
      valueBackend: 'allowed',
      label: "I'm happy to hear from you via SMS",
      labelON: "I'm happy to hear from you via SMS",
    },
  ];
};

/**
 * Get preferences that can be displayed to a user.
 */
export const getPreferencesForView = (isShortform, isShortformWithOptin) => {
  const preferences = getPreferencesOptions();

  // We don't show any preferences for shortforms.
  if (isShortform && !isShortformWithOptin) {
    return [];
  }

  // We show only Email preferences in case of shortform with opt-ins.
  if (isShortformWithOptin) {
    return [preferences.find((option) => option.value === 'email')];
  }

  return preferences;
};

/**
 * Normalise preferences values.
 */
export const normalisePreferencesValues = (values, viewedPreferences) => {
  const normalisedValues = {
    email: 'not_asked',
    phone: 'not_asked',
    sms: 'not_asked',
    post: 'not_asked',
  };

  viewedPreferences.forEach(({ value, valueBackend }) => {
    if (values.includes(value)) {
      normalisedValues[value] = valueBackend;
    } else {
      // Inverted value.
      normalisedValues[value] = valueBackend === 'allowed' ? 'denied' : 'allowed';
    }
  });

  return normalisedValues;
};

/**
 * Set focus to the element by Id.
 *
 * @param string id
 *   Id of the element.
 * @param int timeout
 *   Timeout needed to avoid problems with auto focus.
 */
export const setFocusById = (id, timeout = 100) => {
  const element = document ? document.getElementById(id) : null;
  if (element) {
    setTimeout(() => {
      element.focus();
    }, timeout);
  }
};

/**
 * Get impact text if the text configured for the amount.
 */
export const getImpact = (amount, impacts) => {
  let currentImpact;
  if (amount && Array.isArray(impacts) && impacts.length) {
    currentImpact = impacts.filter((item) => item.amount === amount);
  }

  return currentImpact && currentImpact.length ? currentImpact[0] : null;
};

/**
 * Calculates fundraising costs from the amount.
 */
export const getFundraisingCosts = (amount, formula = 'stripe') => {
  if (formula === 'paypal') {
    // PayPal fee:
    // IE formula: 1.9% of amount + 35 cents.
    // UK formula: 1.9% of amount + 35 penny.
    return (amount * 0.019 + 0.35).toFixed(2);
  } else if (formula === 'revolut') {
    // Revolut fee:
    // IE formula: 1.5% of amount + 25 cents.
    // UK formula: 1.5% of amount + 25 penny.
    return (amount * 0.015 + 0.25).toFixed(2);
  }

  // Stripe fee:
  // IE formula: 1.2% of amount + 25 cents.
  // UK formula: 1.5% of amount + 20 penny.
  return (DEFAULT_COUNTRY_CODE === 'GB' ? amount * 0.015 + 0.2 : amount * 0.012 + 0.25).toFixed(2);
};

/**
 * Preserves trailing zero in case of amounts like '20.50'.
 *
 * Currently, there are no integer amounts that can be increased by
 * fundraising cost and remains integer, however we are going to introduce
 * other formulas in the future.
 */
export const formatAmountWithFundraisingCots = (amount) =>
  amount % 1 !== 0 ? amount.toFixed(2) : amount;

/**
 * Checks all conditions related to fundraising costs state.
 *
 * We need this extended check for the Inline donation form since
 * some payment methods acts immediately and form state doesn't have time
 * to be updated before submission data actually get submitted.
 */
export const isFundraisingCostsApplied = (values) => {
  return values.fundraisingCostsApplied && values.donation_type === 'single';
}

/**
 * Checks fundraising costs is actually visible for payment method.
 *
 * Supports both multistep and inline donation forms.
 */
export const isFundraisingCostsVisible = (values, checkoutType = 'multistep') => {
  if (checkoutType !== 'inline') {
    return values.type === 'single_donation';
  }

  return values.donation_type === 'single' && values.amount.single > 0;
};
