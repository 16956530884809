import { createSlice } from '@reduxjs/toolkit';

const basketClearPopupSlice = createSlice({
  name: 'basketClearPopup',
  initialState: {
    context: null,
    isVisible: false,
  },
  reducers: {
    basketClearPopupShow(state, action) {
      return {
        ...state,
        context: action.payload,
        isVisible: true,
      };
    },
    basketClearPopupHide(state, action) {
      return {
        ...state,
        context: null,
        isVisible: false,
      };
    },
  },
});

export const { basketClearPopupShow, basketClearPopupHide } = basketClearPopupSlice.actions;
export default basketClearPopupSlice.reducer;
