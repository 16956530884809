import NProgress from 'nprogress';
import routerEvents from 'next-router-events';

/**
 * TODO: 'next-router-events' and 'nprogress' are abandoned.
 * We can't use multiple event listeners per event on Router, so
 * we can't use default Router.onRouteChangeStart here, because
 * we can't use this event again in another part of the code.
 * We use custom library to use multiple event listeners for Router.
 * @see https://github.com/zeit/next.js/issues/2033.
 */
if (typeof window !== 'undefined') {
  routerEvents.on('routeChangeStart', () => {
    NProgress.start();
  });

  routerEvents.on('routeChangeComplete', () => {
    NProgress.done();
  });

  routerEvents.on('routeChangeError', () => {
    NProgress.done();
  });
}
