/**
 * Web storage handlers.
 *
 * Wraps Web storage API methods to try/catch for preventing
 * fatal errors when storage is disabled in browser settings.
 */

const debug = require('debug')('cw:web-storage.js');

export function sessionStorageGetItem(item) {
  try {
    const { sessionStorage } = window;
    return sessionStorage.getItem(item) ?? null;
  } catch (error) {
    // Access denied - sessionStorage is disabled.
    debug('Could not get a sessionStorage item. Error: %O', error);
  }
}

export function sessionStorageSetItem(item, data) {
  try {
    const { sessionStorage } = window;
    sessionStorage.setItem(item, data);
  } catch (error) {
    // Access denied - sessionStorage is disabled.
    debug('Could not set a sessionStorage item. Error: %O', error);
  }
}

export function sessionStorageRemoveItem(item) {
  try {
    const { sessionStorage } = window;
    sessionStorage.removeItem(item);
  } catch (error) {
    // Access denied - sessionStorage is disabled.
    debug('Could not remove a sessionStorage item. Error: %O', error);
  }
}
