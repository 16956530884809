import { createSlice } from '@reduxjs/toolkit';

const basketBannerSlice = createSlice({
  name: 'basketBanner',
  initialState: {
    isVisible: false,
    product: null,
  },
  reducers: {
    basketBannerShow(state, action) {
      return {
        ...state,
        isVisible: true,
        product: action.payload,
      };
    },
    basketBannerHide(state, action) {
      return {
        ...state,
        isVisible: false,
      };
    },
  },
});

export const { basketBannerShow, basketBannerHide } = basketBannerSlice.actions;
export default basketBannerSlice.reducer;
