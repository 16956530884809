import htmlDecode from '../utils/htmlDecode';
import fetch from '../utils/fetch.js';
import { methods } from '../utils/payments';
import { DEFAULT_CURRENCY } from '../utils/currency';
import { DEFAULT_COUNTRY_CODE } from '../utils/constants';
import { normalisePreferencesValues } from '../utils/donationForm';

const debug = require('debug')('cw:api:donation.js');

/**
 * Submit donation form to the backend.
 *
 * @param appeal
 *   Appeal ID.
 *
 * @param donationType
 *   Donation type.
 *
 * @param amount
 *   Donation amount.
 *
 * @param paymentGateway
 *   Payment gateway (payment method).
 *
 * @param paymentOptions
 *   Payment options.
 *
 * @param sourceCode
 *   ThankQ Source Code from "How did you hear about concern" field.
 *
 * @param firstName
 *   Donor's first name.
 *
 * @param lastName
 *   Donor's last name.
 *
 * @param email
 *   Donor's email.
 *
 * @param phone
 *   Donor's phone number.
 *
 * @param dsn
 *   Donor serial number.
 *
 * @param viewedPreferencesOptions
 *   List of preferences that were displayed for an user.
 *
 * @param schoolName
 *   School name that donate as school.
 *
 * @param captchaToken
 *   A token generated by Google's reCaptcha.
 *   Should be a long string, but only for credit card payments.
 *   In case of other payments must be empty.
 *
 * @param contactPreferences
 *   Contact preferences of donor. Example:
 *   {
 *     sms: 1,
 *     phone: 0,
 *     email: 1,
 *     post: 1,
 *   }
 */
export const submitDonation = (
  appeal,
  donationType,
  amount,
  paymentGateway,
  paymentOptions,
  sourceCode,
  firstName = '',
  lastName = '',
  email = '',
  phone = '',
  contactPreferences = [],
  address = {},
  giftaid = false,
  dsn = '',
  viewedPreferencesOptions,
  schoolName = '',
  captchaToken = '',
  internalTags = [],
  fundraisingCosts = 0,
  companyName = '',
) =>
  new Promise((resolve, reject) => {
    let payment = {};

    if (paymentGateway) {
      // Find payment method in the list of all payments.
      const method = methods.find((el) => el.id === paymentGateway);
      payment = {
        gateway: paymentGateway,
        type: method.type,
        details: paymentOptions,
      };
    }

    // Prepare contact preferences for the backend.
    const profileContactPrefs = {};
    const normalisedPreferencesValues = normalisePreferencesValues(
      contactPreferences,
      viewedPreferencesOptions,
    );

    Object.keys(normalisedPreferencesValues).forEach((key) => {
      profileContactPrefs[`field_contact_${key}`] = normalisedPreferencesValues[key];
    });

    let administrativeArea = '';

    // We use default country here because country_code is required parameter
    // for Address item in Drupal. Address item contains First name and Last name,
    // therefore First name and Last name won't be saved without country.
    // We want to save First name and Last name for shortform, therefore we use default country.
    let country = DEFAULT_COUNTRY_CODE;

    // If we know donor serial number we don't ask for address and we don't need set country.
    if (!dsn && address.country) {
      // Administrative area can be either text field or select list.
      if (address.administrativeArea && address.administrativeArea[address.country.value]) {
        if (address.administrativeArea[address.country.value].value) {
          administrativeArea = address.administrativeArea[address.country.value].value;
        } else {
          administrativeArea = address.administrativeArea[address.country.value];
        }
      }

      country = address.country.value;
    }

    // We need to normalise value of source codes field because
    // we can get different types of values (select field or radio buttons field)
    const normalisedSourceCode =
      typeof sourceCode === 'object' ? sourceCode.value.trim() : sourceCode.trim();

    fetch('/commerce/order/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      throwNotOk: false,
      body: {
        payment,
        order: {
          type: 'donation',
          field_appeal: appeal,
          field_source_code: normalisedSourceCode,
          field_giftaid: !!giftaid,
          field_internal_tags: internalTags,
          field_fundraising_costs: fundraisingCosts,
          order_items: [
            {
              type: 'donation',
              field_donation_type: donationType,
              title:
                donationType === 'recurring_donation' ? 'Recurring donation' : 'Single donation',
              purchased_entity: {
                sku: 'donation',
              },
              unit_price: {
                number: amount,
                currency_code: DEFAULT_CURRENCY,
              },
            },
          ],
        },
        profile: {
          ...profileContactPrefs,
          field_donor_serial_number: dsn,
          field_phone: phone.trim(),
          field_extra_school_name: schoolName.trim(),
          // Store Cardholder Name in Drupal only for the Monthly credit card donations.
          field_cardholder_name:
            donationType === 'recurring_donation' && paymentOptions.name ? paymentOptions.name : '',
          address: {
            given_name: firstName.trim(),
            family_name: lastName.trim(),
            country_code: country,
            // The UK address autocomplete will have a select dropdown object.
            // Check non-empty addressLine1 when it isn't required by the form (shortform).
            address_line1:
              address.addressLine1 && typeof address.addressLine1 !== 'string'
                ? address.addressLine1.label
                : address.addressLine1,
            address_line2: address.addressLine2,
            locality: address.locality,
            administrative_area: administrativeArea,
            // The UK address autocomplete will have a select dropdown object.
            // Check non-empty postcode when it isn't required by the form (non-UK or shortform).
            postal_code:
              address.postalCode && typeof address.postalCode !== 'string'
                ? address.postalCode.value
                : address.postalCode,
            organization: companyName.trim(),
          },
        },
        user: {
          mail: email.trim(),
        },
        captchaToken,
      },
    })
      .then((response) => {
        resolve({
          orderID: response.statusCode === 201 ? response.body.order_id[0].value : null,
          orderStatus: response.statusCode === 201 ? response.body.state[0].value : null,
          statusCode: response.statusCode,
          errorMessage: response.body.message ? htmlDecode(response.body.message) : '',
        });
      })
      .catch((error) => {
        debug('Could not submit donation.', error);
        reject(error);
      });
  });
