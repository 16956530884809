import * as field from './transforms.fields';
import { getEntityURL } from '../routing';

/**
 * Transforms project type settings (navigation links, logo, cta)
 * into Styleguide-readable format.
 */

export const activism = (pageType, globalSettings) => {
  const props = {};

  if (globalSettings?.headerSettings?.homeNextLink) {
    props.homeNextLink = globalSettings.headerSettings.homeNextLink;
  }

  const links = field.getArrayValue(pageType, 'field_links');
  props.navigationContents = links.map((link) => {
    return field.getLinkValue({ link: link }, 'link');
  });

  props.pageTypeLogo = field.getImage(pageType, 'field_media_logo');
  props.pageTypeVariant = field.getTextValue(pageType, 'field_style_variant');
  const homepage = field.getObjectValue(pageType, 'field_link');
  props.pageTypeLogo.nextLink = getEntityURL(homepage);
  props.cta = field.getLinkValue(pageType, 'field_button');

  props.pageType = pageType;
  props.globalSettings = globalSettings;

  return props;
};

export const no_navigation = () => ({});
