import { combineReducers } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import donationForm, {
  initialState as donationFormInitialState,
} from '../Slices/cnet/donationFormSlice';
import globalSettings from '../Slices/cnet/globalSettingsSlice';
import search from '../Slices/cnet/searchSlice';
import basket from '../Slices/gifts/basketSlice';
import basketBanner from '../Slices/gifts/basketBannerSlice';
import basketClearPopup from '../Slices/gifts/basketClearPopupSlice';
import productsStorage from '../Slices/gifts/productsStorageSlice';

import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import expireReducer from 'redux-persist-expire';
import { createMigrate, persistReducer } from 'redux-persist';

/**
 * Create Noop storage to avoid error message on the Server.
 *
 * See https://github.com/rt2zz/redux-persist/issues/1208#issuecomment-658695446.
 */
const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

// To update local storage for all users - add a manifest below.
// See https://www.freecodecamp.org/news/how-to-use-redux-persist-when-migrating-your-states-a5dee16b5ead.
const migrations = {
  0: (state) => ({ type: '', products: [] }),
};
// To debug migrations add { debug: true } as a second parameter to createMigrate function.
const migration = createMigrate(migrations, { debug: false });

const persistBasketConfig = {
  key: 'basket',
  version: 1,
  storage,
  debug: false,
  migrate: migration,
  // whitelist: ['basket'],
  // blacklist: ['donationForm', 'globalSettings', 'search'],
  transforms: [
    // TODO: Make sure it works as needed. We use another module for it.
    expireReducer('basket', {
      // (Required) Seconds after which store will be expired
      expireSeconds: 7 * 24 * 60 * 60,
    }),
  ],
};

const basketPersistedReducer = persistReducer(persistBasketConfig, basket);

const hydrate = (state = {}, action) => {
  switch (action.type) {
    case HYDRATE: {
      // We need to reset Donation form data immediately after
      // the form is closed, but HYDRATE process can restore the info.
      return { ...state, ...action.payload, donationForm: donationFormInitialState };
    }

    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  // CNET.
  globalSettings,
  donationForm,
  search,
  // Gifts.
  basket: basketPersistedReducer,
  productsStorage,
  basketBanner,
  basketClearPopup,
});

function rootReducer(state, action) {
  const initialState = hydrate(state, action);
  return combinedReducer(initialState, action);
}

export default rootReducer;
