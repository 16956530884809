import config from 'next/config';
import EuroIcon from '../public/static/icons/payments/euro.svg';
import PoundIcon from '../public/static/icons/payments/pound.svg';
import DollarIcon from '../public/static/icons/payments/dollar.svg';

const {
  publicRuntimeConfig: { DEFAULT_CURRENCY },
} = config();

/**
 * Quick access to default currency code.
 */
export { DEFAULT_CURRENCY };

/**
 * Currencies configuration.
 */
export const currencies = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};

export const currencySymbolPath = {
  GBP: <PoundIcon />,
  EUR: <EuroIcon />,
  USD: <DollarIcon />,
};

/**
 * Returns currency symbol by code.
 */
export const getSymbol = (currencyCode) => currencies[currencyCode];

/**
 * Returns formatted amount string.
 */
export const formatAmount = (currencyCode, amount) =>
  `${getSymbol(currencyCode)}${amount.toLocaleString()}`;
