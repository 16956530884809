import * as field from './transforms.fields';
import { getEntityURL, getHomepageLink } from '../routing';
import { SUGGESTED_SEARCHES_TITLE } from './constants';

/**
 * Transforms site wide header settings (menus, logo, etc)
 * into Styleguide-readable format.
 */
export const header = (settings) => {
  const props = {};

  // Link to the front page of the website.
  const homepageLink = getHomepageLink(settings);
  if (homepageLink.href) {
    props.homeNextLink = homepageLink;
  }

  // --
  // Start of primary menu props building.
  // --
  const fieldMenu = field.getObjectValue(settings, 'field_menu_primary');
  const headerPrimaryMenu = field.getArrayValue(fieldMenu, 'links');
  props.navigationContents = headerPrimaryMenu.map((menuItem) => {
    const itemProps = {
      slug: menuItem.title,
      nextLink: field.getEntityURL(menuItem),
      label: menuItem.hasOwnProperty('title') ? menuItem.title : '',
      cta: {
        src: field.getImageURL(menuItem, 'field_featured_image', 'menu_featured'),
        alt: field.getImageAlt(menuItem, 'field_featured_image'),
        title: field.getTextValue(menuItem, 'field_featured_label'),
        description: field.getTextValue(menuItem, 'field_featured_description'),
      },
    };

    // Inject link & label for CTA button in the dropdown navigation if exists.
    const button = field.getObjectValue(menuItem, 'field_featured_button');
    if (button) {
      const link = field.getLinkValue(button, 'field_link');
      const buttonType = field.getTextValue(button, 'entity_bundle');

      if (link && link.label && link.nextLink) {
        itemProps.cta.button = {
          type: buttonType === 'cta_button_primary' ? 'primary' : 'secondary',
          label: link.label,
          nextLink: link.nextLink,
        };
      }
    }

    // Building columns of dropdown menu.
    const columns = [];
    field.getArrayValue(menuItem, 'children').forEach((secondLevelItem) => {
      // Get special setting of second level menu which defines in which column
      // of dropdown menu this item should be rendered.
      let column = field.getNumberValue(secondLevelItem, 'field_column');

      // Make column number (1, 2, 3) match the array indexes.
      if (column > 0) {
        column -= 1;
      }

      // Make sure the associative array is defined.
      if (!columns.hasOwnProperty(column)) {
        columns[column] = [];
      }

      columns[column].push({
        label: secondLevelItem.hasOwnProperty('title') ? secondLevelItem.title : '',
        nextLink: field.getEntityURL(secondLevelItem),
        // inThisSection: field.getBooleanValue(secondLevelItem, 'field_display_on_section_page'),
        contents: field.getArrayValue(secondLevelItem, 'children').map((thirdLevelItem) => ({
          // Third level menu.
          label: thirdLevelItem.hasOwnProperty('title') ? thirdLevelItem.title : '',
          nextLink: field.getEntityURL(thirdLevelItem),
        })),
      });
    });

    itemProps.columns = columns;

    return itemProps;
  });
  // --
  // End of primary menu props building.
  // --

  // --
  // Start of secondary menu props building.
  // --
  const fieldSecondaryMenu = field.getObjectValue(settings, 'field_menu_secondary');
  const headerSecondaryMenu = field.getArrayValue(fieldSecondaryMenu, 'links');
  props.topLinks = headerSecondaryMenu.map((menuItem) => ({
    label: menuItem.hasOwnProperty('title') ? menuItem.title : '',
    nextLink: field.getEntityURL(menuItem),
  }));
  // --
  // End of secondary menu props building.
  // --

  // Primary (Donate Now) CTA.
  const primaryCTA = field.getLinkValue(settings, 'field_header_button');
  if (primaryCTA.label && primaryCTA.nextLink) {
    props.primaryCTA = primaryCTA;

    // Override Donate Now CTA with FRU parameters only in case FRU is enabled
    // and link contains required query parameters.
    const parts = primaryCTA.nextLink.url.split('?');
    if (parts.length > 1) {
      const query = new URLSearchParams(parts[1]);
      if (query.has('cw_fru', 1) && query.has('form')) {
        const isFRUEnabled = field.getBooleanValue(settings, 'field_tools_fundraiseup_enabled');
        if (isFRUEnabled) {
          props.primaryCTA = {
            label: primaryCTA.label,
            nextLink: {
              url: `?${query.toString()}`,
              route: null,
              isExternal: false,
              href: `?${query.toString()}`,
              as: `?${query.toString()}`,
              title: '',
            },
          };
        }
      }
    }
  }

  // Content for the search dropdown.
  props.searchContents = {
    placeholder: 'Search Concern website',
    suggested: {
      title: SUGGESTED_SEARCHES_TITLE,
      items: field.getArrayValue(settings, 'field_suggested_search_links').map((link) => ({
        label: link.label,
        nextLink: field.getEntityURL(link),
      })),
    },
    // Validators and submits are handled in post transforms.
    inputValidators: [],
    onGotoSearch: null,
  };

  return props;
};

/**
 * Transforms site wide footer settings (menus, social links, etc)
 * into Styleguide-readable format.
 */
export const footer = (settings) => {
  const props = {};

  // Primary footer menu.
  const fieldPrimaryMenu = field.getObjectValue(settings, 'field_menu_footer_primary');
  if (fieldPrimaryMenu) {
    props.bigMenu = field.getArrayValue(fieldPrimaryMenu, 'links').map((firstLevelItem) => ({
      label: firstLevelItem.title || '',
      links: firstLevelItem.children.map((secondLevelItem) => ({
        text: secondLevelItem.title || '',
        nextLink: field.getEntityURL(secondLevelItem),
      })),
    }));
  }

  // Secondary footer menu.
  const fieldSecondaryMenu = field.getObjectValue(settings, 'field_menu_footer_secondary');
  if (fieldSecondaryMenu) {
    props.smallMenu = field.getArrayValue(fieldSecondaryMenu, 'links').map((firstLevelItem) => ({
      text: firstLevelItem.title || '',
      nextLink: field.getEntityURL(firstLevelItem),
    }));
  }

  if (props.smallMenu) {
    // Adds a custom link to let a user change their cookiebot preferences.
    props.smallMenu.push({
      text: 'Manage my cookies',
      jsSnippet: 'Cookiebot.renew()',
    });
  }

  // Footer copy message at the end of the page.
  props.copy = field.getTextValue(settings, 'field_footer_copy');

  // Footer partner logos.
  const logos = field.getArrayValue(settings, 'field_footer_partners_logos');
  if (logos) {
    props.logos = logos.map((logo) => ({
      logo: field.getImage(logo, 'field_media_image', 'footer_logos'),
      link: field.getLinkValue(logo, 'field_link').nextLink.url,
    }));
  }

  // Footer social links.
  props.socialLinks = field.getArrayValue(settings, `field_social_networks`).map((item) => ({
    type: field.getTextValue(item, 'field_variant'),
    accounts: field.getArrayValue(item, 'field_links').map((account) => ({
      label: account.label,
      nextLink: getEntityURL(account),
    })),
  }));

  // A flag to enable Google Translate widget.
  props.googleTranslateEnabled = field.getBooleanValue(settings, 'field_tools_google_translate');

  return props;
};

/**
 * General site-wide settings.
 */
export const general = (settings) => {
  const props = {};

  // General flag whether to show captcha or not.
  props.showCreditCardCaptcha = field.getBooleanValue(settings, 'field_checkout_show_captcha');

  // General flag whether to show captcha or not for Google / Apple payments.
  props.showGoogleAppleCaptcha = field.getBooleanValue(
    settings,
    'field_checkout_safe_show_captcha',
  );

  // General flag whether to show captcha or not for webforms.
  props.showWebformsCaptcha = field.getBooleanValue(settings, 'field_webform_show_captcha');

  // General flag to show Revolut payment method.
  props.showRevolutStripe = field.getBooleanValue(settings, 'field_revolut_stripe_enabled');

  // Content for the footer of the donation checkout page.
  props.checkoutFooterColumnOneHeader = field.getTextValue(settings, 'field_first_header');
  props.checkoutFooterColumnOneContent = field.getTextValue(settings, 'field_first_content');
  props.checkoutFooterColumnTwoHeader = field.getTextValue(settings, 'field_second_header');
  props.checkoutFooterColumnTwoContent = field.getTextValue(settings, 'field_second_content');
  props.checkoutFooterCopy = field.getTextValue(settings, 'field_checkout_footer_copy');
  props.checkoutFooterLogos = field
    .getArrayValue(settings, 'field_checkout_footer_logos')
    .map((logo) => field.getImage(logo, 'field_media_image', 'checkout_footer_logo'));

  // List of links suggested for search.
  props.suggestedSearchLinks = field.getArrayValue(settings, 'field_suggested_search_links');

  // Text to display above a shortform opt-in step.
  props.emailOptinDescription = field.getTextValue(settings, 'field_contact_shortform_desc');

  // A flag to enable / disable Convert A/B testing tool on the website.
  props.convertEnabled = field.getBooleanValue(settings, 'field_tools_convert_enabled');

  // A flag to enable / disable Fundraise Up integration on the website.
  props.fundraiseUpEnabled = field.getBooleanValue(settings, 'field_tools_fundraiseup_enabled');

  // The cookiebot ID.
  props.cookiebotId = field.getTextValue(settings, 'field_cookiebot_domain_group_id');
  // Cookie Consent settings. Contains texts for Cookie Consent Placeholders for embedded
  // body blocks. Body blocks with embedded content, e.g. Twitter or Instagram blocks, require
  // a certain level of cookie consent from a user before the content can be displayed. For users
  // who opted-out from required cookies, the placeholder block will be displayed
  // with an option to opt-in.
  props.cookieConsentVideo = {
    cookieDialogHeading: field.getTextValue(settings, 'field_video_heading'),
    cookieDialogDescription: field.getTextValue(settings, 'field_video_description'),
  };
  props.cookieConsentSoundCloud = {
    cookieDialogHeading: field.getTextValue(settings, 'field_soundcloud_heading'),
    cookieDialogDescription: field.getTextValue(settings, 'field_soundcloud_description'),
  };
  props.cookieConsentTwitter = {
    cookieDialogHeading: field.getTextValue(settings, 'field_twitter_heading'),
    cookieDialogDescription: field.getTextValue(settings, 'field_twitter_description'),
  };
  props.cookieConsentFacebook = {
    cookieDialogHeading: field.getTextValue(settings, 'field_facebook_heading'),
    cookieDialogDescription: field.getTextValue(settings, 'field_facebook_description'),
  };
  props.cookieConsentInstagram = {
    cookieDialogHeading: field.getTextValue(settings, 'field_instagram_heading'),
    cookieDialogDescription: field.getTextValue(settings, 'field_instagram_description'),
  };
  props.cookieConsentTiktok = {
    cookieDialogHeading: field.getTextValue(settings, 'field_tiktok_heading'),
    cookieDialogDescription: field.getTextValue(settings, 'field_tiktok_description'),
  };
  props.cookieConsentHtmlEmbedCode = {
    cookieDialogHeading: field.getTextValue(settings, 'field_embed_heading'),
    cookieDialogDescription: field.getTextValue(settings, 'field_embed_description'),
  };

  return props;
};

/**
 * Internal helper.
 * Recursively handles menu items and builds hierarchy.
 */
const getMenuLinks = (menuItem) => {
  const item = {
    url: field.getEntityURL(menuItem),
    title: menuItem.title || '',
  };

  if (menuItem.children && menuItem.children.length > 0) {
    item.children = menuItem.children.map((child) => getMenuLinks(child));
  }

  return item;
};

/**
 * Builds the whole hierarchy for the primary navigation.
 */
export const primaryNavigation = (settings) => {
  const fieldMenu = field.getObjectValue(settings, 'field_menu_primary');
  const headerPrimaryMenu = field.getArrayValue(fieldMenu, 'links');
  return headerPrimaryMenu.map((menuItem) => getMenuLinks(menuItem));
};

/**
 * Top banner settings.
 */
export const topBanner = (settings) => ({
  appealBanner: {
    active: field.getBooleanValue(settings, 'field_banner_active'),
    emergency: field.getBooleanValue(settings, 'field_banner_emergency'),
    emergencyTag: field.getTextValue(settings, 'field_banner_emergency_tag'),
    label: field.getTextValue(settings, 'field_banner_label'),
    text: field.getTextValue(settings, 'field_banner_text'),
    url: field.getLinkValue(settings, 'field_banner_url'),
    visibility: {
      visibleOnContentType: field
        .getArrayValue(settings, 'field_banner_visible_on')
        .map((fieldItem) => fieldItem.value),
      pathMatches: field
        .getArrayValue(settings, 'field_banner_path_matches')
        .map((fieldItem) => fieldItem.value),
      pathStarts: field
        .getArrayValue(settings, 'field_banner_path_starts_with')
        .map((fieldItem) => fieldItem.value),
      pathExcludes: field
        .getArrayValue(settings, 'field_banner_path_exclude')
        .map((fieldItem) => fieldItem.value),
    },
  },
  eventBanner: {
    active: field.getBooleanValue(settings, 'field_event_banner_active'),
    text: field.getTextValue(settings, 'field_event_banner_text'),
    cta1: field.getLinkValue(settings, 'field_event_banner_cta1'),
    cta2: field.getLinkValue(settings, 'field_event_banner_cta2'),
    visibility: {
      pathMatches: field
        .getArrayValue(settings, 'field_event_banner_path_matches')
        .map((fieldItem) => fieldItem.value),
      pathStarts: field
        .getArrayValue(settings, 'field_event_banner_path_starts')
        .map((fieldItem) => fieldItem.value),
      pathExcludes: field
        .getArrayValue(settings, 'field_event_banner_exclude')
        .map((fieldItem) => fieldItem.value),
    },
  },
});
