const { pathToRegexp } = require('path-to-regexp');
const { parse } = require('url');

// This file defines frontend only routes.
// Routing checks for these routes first, and if the route matches
// frontend only routes, then there will be no backend request to check the
// page content.

// List of routes which exist only in the frontend application.
const routes = [
  {
    route: '/_dynamic-listing',
    path: '/content/:type/:value',
  },
  {
    route: '/search',
    path: '/search',
  },
  {
    route: '/policy-cookie',
    path: '/cookie-policy',
  },
  // Disabled as the form look & feel is now outdated and broken.
  // We can consider re-enabling later.
  // See https://www.pivotaltracker.com/story/show/175304069/comments/222414283
  // {
  //   route: '/_giftaid-shortform',
  //   path: '/gift-aid/opt-in',
  // },
];

/**
 * Finds internal route in the pages available at the frontend app only.
 * @param URL
 *   Full URL string. I.e. /content/one/two?page=1.
 * @returns {*}
 *   Matched route & route query with dynamic values from the URL.
 */
const matchAppOnlyRoute = (URL) => {
  // Get object with different parts of the URL.
  const parsedUrl = parse(URL, true);

  // By default pass into the request the same query details as the
  // browser URL has.
  const hrefQuery = parsedUrl.query;

  // Finds matching route using regexp.
  const matchingRoute = routes.find((route) => {
    const { regexp, keys } = pathToRegexp(route.path);
    let values = regexp.exec(parsedUrl.pathname);
    if (values) {
      // Remove the first array value as it always contains the full url string.
      values = values.slice(1);

      // Adds dynamic parts of the url to the query object.
      keys.forEach((key, index) => {
        hrefQuery[key.name] = decodeURIComponent(values[index]);
      });

      return true;
    }
    return false;
  });

  // Add a little flag that tells _app.js to not make a request to the backend
  // searching for the matching entity.
  hrefQuery.isAppOnlyRoute = true;

  if (matchingRoute) {
    return { href: matchingRoute.route, query: hrefQuery };
  }

  return null;
};

/**
 * Converts object of URL query values into query string
 * valid for the browser.
 */
const toQuerystring = (obj) =>
  Object.keys(obj)
    .filter((key) => obj[key] !== null && obj[key] !== undefined)
    .map((key) => {
      let value = obj[key];

      if (Array.isArray(value)) {
        value = value.join('/');
      }
      return [encodeURIComponent(key), encodeURIComponent(value)].join('=');
    })
    .join('&');

// Export helpers.
module.exports = {
  matchAppOnlyRoute,
  toQuerystring,
};
