/* global dataLayer */

import { getProductQuantity, getTotal } from './basket';
import { DEFAULT_CURRENCY } from '../currency';
import { pushGtmEvent } from '../gtm';

/**
 * Integration with Enhanced Ecommerce.
 * See more: https://developers.google.com/tag-manager/enhanced-ecommerce#details
 */
export const pushEcommerceDetail = (data) => {
  pushGtmEvent({
    event: 'ecommerceDetail',
    ecommerce: {
      detail: {
        products: [
          {
            name: data.title,
            id: data.id,
            category: data.type,
          },
        ],
      },
    },
  });
};

/**
 * Push ecommerceAdd event.
 */
export const pushEcommerceAdd = (data) => {
  pushGtmEvent({
    event: 'ecommerceAdd',
    ecommerce: {
      currencyCode: DEFAULT_CURRENCY,
      add: {
        products: [
          {
            name: data.product.title,
            id: data.product.id,
            category: data.product.type,
            price: data.amount ? data.amount : data.product.price[DEFAULT_CURRENCY].amount,
            // Quantity is always 1 per action.
            quantity: 1,
          },
        ],
      },
    },
  });
};

/**
 * Push ecommerceCheckout event.
 */
export const pushEcommerceCheckout = (data) => {
  pushGtmEvent({
    event: 'ecommerceCheckout',
    ecommerce: {
      currencyCode: DEFAULT_CURRENCY,
      checkout: {
        products: data.map((product) => ({
          name: product.data.title,
          id: product.data.id,
          category: product.data.type,
          price: product.data.price[DEFAULT_CURRENCY].amount,
          quantity: getProductQuantity(product),
        })),
      },
    },
  });
};

/**
 * Push ecommerceCheckoutStep event.
 * todo: in ideal case we should use pushCheckoutStep from cnet.gtm.
 */
export const pushEcommerceCheckoutStep = (action, products) => {
  pushGtmEvent({
    event: 'ecommerceCheckoutStep',
    ecommerce: {
      currencyCode: DEFAULT_CURRENCY,
      checkout: {
        actionField: { step: action.step, option: action.option },
        products: products.map((product) => ({
          name: product.data.title,
          id: product.data.id,
          category: product.data.type,
          price: product.data.price[DEFAULT_CURRENCY].amount,
          quantity: getProductQuantity(product),
        })),
      },
    },
  });
};

/**
 * Push ecommercePurchase event.
 */
export const pushEcommercePurchase = (orderId, orderProducts, couponState) => {
  const products = orderProducts
    .map((item) => {
      const types = {
        postal: 0,
        email: 0,
        print: 0,
      };

      const products = [];

      if (item.cards) {
        Object.values(item.cards).map((card) => {
          types[card.type]++;
        });
      } else {
        products.push({
          variant: '',
          name: item.data.title,
          id: item.data.id,
          category: item.data.type,
          price: item.data.price[DEFAULT_CURRENCY].amount,
          quantity: item.quantity,
        });
      }

      for (const type in types) {
        if (types[type]) {
          let variant = '';

          if (type === 'postal') {
            variant = 'Postal';
          } else if (type === 'email') {
            variant = 'E-card';
          } else if (type === 'print') {
            variant = 'Print at Home';
          }

          products.push({
            variant,
            name: item.data.title,
            id: item.data.id,
            category: item.data.type,
            price: item.data.price[DEFAULT_CURRENCY].amount,
            quantity: types[type],
          });
        }
      }

      return products;
    })
    .flat();

  const result = {
    event: 'ecommercePurchase',
    ecommerce: {
      currencyCode: DEFAULT_CURRENCY,
      purchase: {
        actionField: {
          id: orderId,
          revenue: getTotal(orderProducts),
        },
        products,
      },
    },
  };

  const { code, discount, totalPrice } = couponState;
  // Add coupon.
  if (code) {
    result.ecommerce.purchase.actionField.coupon = code;
  }
  // Add discount if it was applied.
  if (discount && totalPrice) {
    result.ecommerce.purchase.actionField.revenue = totalPrice;
    result.ecommerce.purchase.actionField.discount = discount;
  }

  pushGtmEvent(result);
};
