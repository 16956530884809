import { createSlice } from '@reduxjs/toolkit';

const productsStorageSlice = createSlice({
  name: 'productsStorage',
  initialState: {
    freeGifts: {},
    corporateGifts: {},
    gifts: {},
    donations: {},
  },
  reducers: {
    productsStorageSaveProducts(state, action) {
      return {
        ...action.payload,
      };
    },
  },
});

export const { productsStorageSaveProducts } = productsStorageSlice.actions;
export default productsStorageSlice.reducer;
