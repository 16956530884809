import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';
import { ENVIRONMENT } from '../utils/constants';
// Import listener which can convert redux actions into promises;
import createReduxPromiseListener from 'redux-promise-listener';
// Import all our custom sagas.
import sagas from './sagas';
// Import all our custom reducers.
import reducers from './reducers';

// Create a middleware for conversion of store actions into promises.
const reduxPromiseListener = createReduxPromiseListener();

export const makeStore = () => {
  // Create saga middleware.
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([sagaMiddleware, reduxPromiseListener.middleware]),
    devTools: ENVIRONMENT !== 'production',
  });
  store.__persistor = persistStore(store);
  // Run sagas on server.
  store.sagaTask = sagaMiddleware.run(sagas);

  // Return the store.
  return store;
};

// TODO: try to use createAsyncThunk instead.
export const promiseListener = reduxPromiseListener;

export const wrapper = createWrapper(makeStore, { debug: false });
