import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { basketBannerShow } from './basketBannerSlice';
import { getTotal } from '../../../utils/Gifts/basket';
import { DEFAULT_CURRENCY } from '../../../utils/currency';
import {
  isCorporateGift,
  isCustomPrice,
  isFreeProduct,
  isSantaLetterProduct,
} from '../../../utils/Gifts/products';
import { basketClearPopupShow } from './basketClearPopupSlice';
import { getOrderItems } from '../../../api/order';
import fetch from '../../../utils/fetch';
import { pushEcommerceAdd as gtmPushEcommerceAdd } from '../../../utils/Gifts/gtm';
import { toast } from 'react-toastify';

export const FetchCoupon = createAsyncThunk('basket/fetchCoupon', async (data, thunkAPI) => {
  const response = await fetch('/commerce-promotion/validate-coupon', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  });

  return response.body.errorMessage ? response.body : { code: data.coupon, ...response.body };
});

const couponInitialValues = {
  code: '',
  isPending: false,
  totalPrice: null,
  discount: null,
  offerType: null,
  freeGiftId: null,
};
const initialState = {
  type: '',
  products: [],
  coupon: couponInitialValues,
};

const basketSlice = createSlice({
  name: 'basket',
  initialState: initialState,
  reducers: {
    basketAddProduct(state, action) {
      const product = action.payload;
      // Search for the product being added in the basket.
      const index = state.products.findIndex((element) => element.id === product.id);

      // If the product was found in the basket, then
      // fetch it and increase the quantity.
      if (index !== -1) {
        const storedProduct = state.products[index];
        state.products[index] = {
          ...storedProduct,
          quantity: storedProduct.quantity + 1,
        };
        return;
      }

      // Define type of the basket.
      if (!state.products.length) {
        state.type = action.payload.type.startsWith('gift_corporate') ? 'corporate' : 'gift';
      }

      // If the product doesn't exist in the basket yet,
      // simply add.
      state.products = [
        ...state.products,
        {
          id: product.id,
          quantity: 1,
          path: product.path,
          data: product,
        },
      ];
    },
    basketQuantityIncrease(state, action) {
      // Search for the product being added in the basket.
      const index = state.products.findIndex((element) => element.id === action.payload);
      state.products = [
        ...state.products.slice(0, index),
        {
          ...state.products[index],
          quantity: state.products[index].quantity + 1,
        },
        ...state.products.slice(index + 1),
      ];
    },
    basketQuantityDecrease(state, action) {
      const index = state.products.findIndex((element) => element.id === action.payload);
      state.products = [
        ...state.products.slice(0, index),
        {
          ...state.products[index],
          quantity: state.products[index].quantity - 1,
        },
        ...state.products.slice(index + 1),
      ];
    },
    basketRemoveCardsFromProduct(state, action) {
      // Search for the product being added in the basket.
      const index = state.products.findIndex((element) => element.id === action.payload.productId);

      if (index !== -1) {
        let cardsKey = 'cards';
        if (state.products[index].data.type === 'gift_santa_letter') {
          cardsKey = 'santaLetters';
        }

        // Update Product cards.
        state.products[index][cardsKey] = state.products[index][cardsKey].filter(
          (value, index) => action.payload.indexes.indexOf(index) === -1,
        );

        // Update quantity.
        state.products[index].quantity = state.products[index][cardsKey].length;

        // If all cards are deleted, we can simply delete the product.
        if (!state.products[index][cardsKey].length) {
          state.products = [...state.products.slice(0, index), ...state.products.slice(index + 1)];

          if (!state.products.length) {
            state.type = '';
          }
        }
      }
    },
    basketRemoveProduct(state, action) {
      // Search for the product being added in the basket.
      const index = state.products.findIndex((element) => element.id === action.payload);

      // If the product was found in the basket, then
      // we simply remove it.
      if (index !== -1) {
        state.products = [...state.products.slice(0, index), ...state.products.slice(index + 1)];

        if (!state.products.length) {
          state.type = '';
        }
      }
    },
    basketReset(state, action) {
      return initialState;
    },
    basketAddCustomPriceProduct(state, action) {
      const index = state.products.findIndex((element) => isCustomPrice(element.data));

      // If custom price product already exists in the basket
      // then we take its object from the redux storage.
      if (index !== -1) {
        state.type = action.payload.product.type.startsWith('gift_corporate')
          ? 'corporate'
          : 'gift';
        state.products[index].data.price[DEFAULT_CURRENCY].amount = action.payload.amount;
      } else {
        // Otherwise we use the product object being added
        // to the basket.
        state.type = action.payload.product.type.startsWith('gift_corporate')
          ? 'corporate'
          : 'gift';
        state.products.push({
          id: action.payload.product.id,
          quantity: 1,
          data: {
            ...action.payload.product,
            price: {
              [DEFAULT_CURRENCY]: {
                ...action.payload.product.price[DEFAULT_CURRENCY],
                amount: action.payload.amount,
              },
            },
          },
        });
      }
    },
    basketUpdateSantaLettersForProduct(state, action) {
      const index = state.products.findIndex((element) => element.id === action.payload.productId);
      state.products[index].santaLetters = action.payload.santaLetters;
    },
    basketUpdateCardsForProduct(state, action) {
      const index = state.products.findIndex((element) => element.id === action.payload.productId);
      state.products[index].cards = action.payload.cards;
    },
    basketResetCoupon(state, action) {
      state.coupon = { ...couponInitialValues };
    },
    basketResetCouponErrorMessage(state, action) {
      state.coupon.errorMessage = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchCoupon.fulfilled, (state, action) => {
      state.coupon = {
        ...couponInitialValues,
        ...action.payload,
        isPending: false,
      };
    });
    builder.addCase(FetchCoupon.rejected, (state, action) => {
      state.coupon = {
        ...couponInitialValues,
        errorMessage: "This voucher code isn't valid",
      };
    });
    builder.addCase(FetchCoupon.pending, (state, action) => {
      state.coupon.isPending = true;
    });
  },
});

export const ApplyCoupon = (payload) => async (dispatch, getState) => {
  await dispatch(FetchCoupon(payload));
  dispatch(AddOrRemoveFreeProductByCoupon());
};

export const RemoveCoupon = (payload) => (dispatch, getState) => {
  const state = getState();
  dispatch(basketResetCoupon());
  dispatch(AddOrRemoveFreeProductByCoupon());
  toast.info(
    state.basket.coupon.offerType === 'free_gift' ? 'Free gift removed' : 'Voucher code removed',
    { autoClose: 4000 },
  );
};

export const ReApplyCoupon = (payload) => (dispatch, getState) => {
  const state = getState();

  const { code, offerType } = state.basket.coupon;
  if (code) {
    const data = {
      order: {
        type: 'gift',
        order_items: getOrderItems(state.basket.products, {}),
      },
      coupon: code,
    };
    dispatch(ApplyCoupon(data));
    const updatedState = getState();
    if (
      updatedState.basket.coupon.code !== code &&
      (updatedState.basket.coupon.offerType === 'free_gift' || offerType === 'free_gift')
    ) {
      dispatch(AddOrRemoveFreeProductByCoupon());
    }
  }
};

export const AddCustomPriceProduct = (payload) => (dispatch, getState) => {
  const payloadType = payload.product.type.startsWith('gift_corporate') ? 'corporate' : 'gift';
  const type = getState().basket.type;

  if (type && payloadType !== type) {
    return dispatch(basketClearPopupShow(payload));
  }

  dispatch(basketAddCustomPriceProduct(payload));
  dispatch(AddOrRemoveFreeProduct());
  dispatch(ReApplyCoupon());

  gtmPushEcommerceAdd(payload);

  toast.dismiss();
  return toast.success(
    `Donation ${payload.existingCustomPriceProduct ? 'updated' : 'added'}, thanks for showing your support.`,
    { autoClose: 5000 },
  );
};

export const UpdateCardsForProduct = (payload) => (dispatch, getState) => {
  const productId = payload.productId;
  const card = payload.card;
  const state = getState();
  const basketProducts = state.basket.products;
  const index = basketProducts.findIndex((element) => element.id === productId);
  const product = basketProducts[index];

  // Do nothing if product isn't in the basket.
  if (!product) {
    return;
  }

  // Do nothing if product doesn't support Cards by architecture.
  if (
    isCustomPrice(product.data) ||
    isFreeProduct(product.data) ||
    isCorporateGift(product.data) ||
    isSantaLetterProduct(product.data)
  ) {
    return;
  }

  // Set 'email' type by default if postalCard inactive and 'postal' in any other cases.
  const defaultCardType =
    !product.data.postalCardActive && product.data.ecardActive ? 'email' : 'postal';
  const quantity = product.quantity;
  const productCards = product.cards && product.cards.length ? product.cards : [];

  let updatedProductCards = productCards;
  if (productCards.length > quantity) {
    updatedProductCards = productCards.slice(quantity);
  }
  if (productCards.length < quantity) {
    updatedProductCards = productCards.concat(
      Array.apply(null, Array(quantity - productCards.length)).map((item) => ({
        type: defaultCardType,
        emailFormData: {},
      })),
    );
  }
  if (card && updatedProductCards[card.index - 1]) {
    updatedProductCards = [
      ...updatedProductCards.slice(0, card.index - 1),
      { ...card },
      ...updatedProductCards.slice(card.index),
    ];
  }

  dispatch(basketUpdateCardsForProduct({ productId, cards: updatedProductCards }));
};

export const UpdateSantaLetterCardForProduct = (payload) => (dispatch, getState) => {
  const productId = payload.productId;
  const card = payload.card;
  const state = getState();
  const basketProducts = state.basket.products;
  const index = basketProducts.findIndex((element) => element.id === productId);
  const product = basketProducts[index];

  // Do nothing if product isn't in the basket.
  if (!product) {
    return;
  }
  // Do nothing if product isn't a Santa Letter product.
  if (!isSantaLetterProduct(product.data)) {
    return;
  }

  // Set 'email' type by default if postalCard inactive and 'postal' in any other cases.
  const defaultCardType = 'santa_letter';
  const quantity = product.quantity;
  const productSantaLetters =
    product.santaLetters && product.santaLetters.length ? product.santaLetters : [];

  let updatedProductSantaLetters = productSantaLetters;
  if (productSantaLetters.length > quantity) {
    updatedProductSantaLetters = productSantaLetters.slice(quantity);
  }
  if (productSantaLetters.length < quantity) {
    updatedProductSantaLetters = productSantaLetters.concat(
      Array.apply(null, Array(quantity - productSantaLetters.length)).map((item) => ({
        type: defaultCardType,
        ...card,
      })),
    );
  }
  if (card && updatedProductSantaLetters[card.index - 1]) {
    updatedProductSantaLetters = [
      ...updatedProductSantaLetters.slice(0, card.index - 1),
      { ...card },
      ...updatedProductSantaLetters.slice(card.index),
    ];
  }

  dispatch(
    basketUpdateSantaLettersForProduct({ productId, santaLetters: updatedProductSantaLetters }),
  );
};

export const AddProduct = (payload) => (dispatch, getState) => {
  const payloadType = payload.type.startsWith('gift_corporate') ? 'corporate' : 'gift';

  const type = getState().basket.type;

  if (type && payloadType !== type) {
    return dispatch(basketClearPopupShow(payload));
  }

  dispatch(basketAddProduct(payload));
  // Initialise cards for product.
  dispatch(UpdateCardsForProduct({ productId: payload.id }));
  dispatch(AddOrRemoveFreeProduct());
  dispatch(ReApplyCoupon());
  dispatch(basketBannerShow(payload));

  gtmPushEcommerceAdd({ product: payload });
};

export const RemoveProduct = (payload) => (dispatch, getState) => {
  if (payload.indexes?.length) {
    dispatch(basketRemoveCardsFromProduct(payload));
  } else {
    dispatch(basketRemoveProduct(payload.productId));
  }
  dispatch(AddOrRemoveFreeProduct());
  dispatch(ReApplyCoupon());
};

export const IncreaseProductQuantity = (payload) => (dispatch, getState) => {
  dispatch(basketQuantityIncrease(payload));
  // Increase cards for product.
  dispatch(UpdateCardsForProduct({ productId: payload }));
  dispatch(AddOrRemoveFreeProduct());
  dispatch(ReApplyCoupon());
};

export const DecreaseProductQuantity = (payload) => (dispatch, getState) => {
  dispatch(basketQuantityDecrease(payload));
  // Update cards for product.
  dispatch(UpdateCardsForProduct({ productId: payload }));
  dispatch(AddOrRemoveFreeProduct());
  dispatch(ReApplyCoupon());
};

export const AddOrRemoveFreeProductByCoupon = () => (dispatch, getState) => {
  const state = getState();
  const coupon = state.basket.coupon;
  if (coupon.freeGiftId) {
    const freeGiftId = +coupon.freeGiftId;
    const freeProduct = state.productsStorage['freeGifts'][freeGiftId];

    if (!freeProduct) {
      // Make sure there is no Free Gift in the basket
      dispatch(basketRemoveProduct(freeGiftId));
    } else {
      // Make sure Free Gift is added to the basket.
      const basketProductIndex = state.basket.products.findIndex(
        (product) => product.id === freeGiftId && product.data.offerType === 'free_gift_by_coupon',
      );

      if (basketProductIndex === -1) {
        dispatch(
          basketAddProduct({
            ...freeProduct,
            offerType: 'free_gift_by_coupon',
          }),
        );
      }
    }
  } else {
    // Make sure there is no Free Gift in the basket
    const toRemoveProducts = state.basket.products.filter(
      (product) => product.data.offerType === 'free_gift_by_coupon',
    );
    toRemoveProducts.forEach((item) => dispatch(basketRemoveProduct(item.id)));
  }
};

export const AddOrRemoveFreeProduct = () => (dispatch, getState) => {
  const state = getState();
  const basketProducts = state.basket.products;
  const freeProducts = state.productsStorage.freeGifts;
  const total = getTotal(basketProducts);
  const basketType = state.basket.type;

  if (basketType !== 'gift') {
    return;
  }

  Object.values(freeProducts).forEach((product) => {
    if (!product.automaticallyAddToBasket) {
      return;
    }
    const index = basketProducts.findIndex((element) => element.id === product.id);

    if (product.amount.currency === DEFAULT_CURRENCY && total >= product.amount.amount) {
      // Add product to the basket If it doesn't exist there.
      if (index === -1) {
        dispatch(basketAddProduct(product));
      }
    } else if (index !== -1) {
      // Removes product from the basket if product exists in the basket and total value less then defined limit.
      dispatch(basketRemoveProduct(product.id));
    }
  });

  // Remove unpublished Free products if they are in the basket.
  basketProducts
    .filter((product) => isFreeProduct(product.data) && !freeProducts[product.id])
    .forEach((product) => dispatch(basketRemoveProduct(product.id)));
};

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
  basketAddProduct,
  basketRemoveProduct,
  basketRemoveCardsFromProduct,
  basketReset,
  basketAddCustomPriceProduct,
  basketQuantityIncrease,
  basketQuantityDecrease,
  basketUpdateCardsForProduct,
  basketResetCoupon,
  basketResetCouponErrorMessage,
  basketUpdateSantaLettersForProduct,
} = basketSlice.actions;

// Export the slice reducer as the default export
export default basketSlice.reducer;
