import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  // Reflects current donation form state provided by react-final-form.
  // Includes form states like submitting, invalid, as well as field values.
  form: {
    values: {},
  },
  // Result of Stripe Payment Request Button availability check.
  stripeButtonAvailability: null,
  paymentCompleted: false,
  orderID: null,
  // Should be set to true when processed by backend or third parties.
  isLocked: false,
  formErrorMessage: null,
};

const donationFormSlice = createSlice({
  name: 'donationForm',
  initialState,
  reducers: {
    // Give case reducers meaningful past-tense "event"-style names
    donationFormStateUpdate(state, action) {
      const isStepChanged =
        state.form.values.step && state.form.values.step.index !== action.payload.values.step.index;
      const isPaymentMethodChanged =
        state.form.values.payment_method !== action.payload.values.payment_method;

      return {
        ...state,
        form: action.payload,
        // Switching between steps resets any previously submitted order along
        // with its error messages and payment status.
        formErrorMessage:
          action.payload.dirtySinceLastSubmit || isStepChanged ? null : state.formErrorMessage,
        paymentCompleted:
          action.payload.dirtySinceLastSubmit || isStepChanged ? false : state.paymentCompleted,
        orderID:
          action.payload.dirtySinceLastSubmit || isStepChanged || isPaymentMethodChanged
            ? null
            : state.orderID,
      };
    },
    donationFormPaymentSuccess(state, action) {
      return {
        ...state,
        paymentCompleted: true,
        orderID: action.payload,
        isLocked: false,
      };
    },
    donationFormSubmit(state, action) {
      return state;
    },
    donationFormSubmitSuccess(state, action) {
      return {
        ...state,
        orderID: action.payload,
        formErrorMessage: null,
        showError: false,
      };
    },
    donationFormSubmitError(state, action) {
      return {
        ...state,
        orderID: null,
        formErrorMessage: {
          message: action.payload.errorMessage,
          subline: action.payload.subline,
          additionalMessage: action.payload.additionalMessage,
        },
        showError: true,
      };
    },
    donationFormStepValidationErrors(state, action) {
      // console.log('STATE - ', state, action);
      state.topBanner = action.payload;
    },
    donationFormHideSubmitError(state, action) {
      return {
        ...state,
        formErrorMessage: null,
        showError: false,
      };
    },
    donationFormReset(state, action) {
      return initialState;
    },
    donationFormLock(state, action) {
      return {
        ...state,
        isLocked: true,
      };
    },
    donationFormUnLock(state, action) {
      return {
        ...state,
        isLocked: false,
      };
    },
    donationFormStripeButtonAvailability(state, action) {
      return {
        ...state,
        stripeButtonAvailability: action.payload,
      };
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
  donationFormUnLock,
  donationFormHideSubmitError,
  donationFormLock,
  donationFormPaymentSuccess,
  donationFormReset,
  donationFormStripeButtonAvailability,
  donationFormStateUpdate,
  donationFormStepValidationErrors,
  donationFormSubmit,
  donationFormSubmitError,
  donationFormSubmitSuccess,
} = donationFormSlice.actions;

// Export the slice reducer as the default export
export default donationFormSlice.reducer;
