/**
 * Web-vitals related functions.
 */
import { pushWebVitals } from './gtm';
import { sessionStorageGetItem, sessionStorageRemoveItem, sessionStorageSetItem } from './web-storage';

/* global Cookiebot */

function webVitalsAddToStorage(metric) {
  // We are using session storage instead of local storage by two reasons:
  // 1. Metrics don't contain data about the page they are related.
  // All metrics automatically assigned to current page. If we would store
  // metrics in local storage it may contain events from other tabs and pages.
  // 2. Local storage somehow magically cleaned on CookiebotOnAccept event,
  // so that we can't obtain previously stored metrics.
  const localEvents = sessionStorageGetItem('cw_web_vitals');
  // Parse the string to an object.
  const events = localEvents ? JSON.parse(localEvents) : [];
  // Add our current metric to the events.
  events.push(metric);
  // Store the updated events in local storage.
  sessionStorageSetItem('cw_web_vitals', JSON.stringify(events));
}

export function webVitalsHandler(event = null) {
  // We need a single handler for multiple possible cases:
  // 1. Cookie consent is not given yet and user clicks directly on
  // "Accept All" button. In this case reportWebVitals method called
  // before CookiebotOnAccept event. We need to push current and locally
  // stored metrics but with a small delay.
  // 2. Cookie consent is not given yet but user clicks somewhere on
  // the page before actually accept the cookie. In this case
  // CookiebotOnAccept event is fired but no metric is passed. We need to push
  // locally stored metrics only but with a small delay.
  // 3. Cookie consent is already given (normal case), reportWebVitals method
  // is fired but there is no local metrics, only the current one
  // needs to be pushed.
  //
  // Called on metric report (cases 1 and 3).
  if (event && event.label === 'web-vital') {
    // If consent has been defined.
    if (typeof Cookiebot !== 'undefined' && Cookiebot.hasResponse) {
      // If consent has been given...
      if (Cookiebot.consent && Cookiebot.consent.statistics) {
        const localEvents = sessionStorageGetItem('cw_web_vitals');
        if (localEvents) {
          // There are events stored locally, most likely cookie consent is just given.
          // Adding current event to the end of the list and pushing all of them.
          const events = localEvents ? JSON.parse(localEvents) : [];
          events.push(event);

          events.forEach((event) => {
            // Adding a small delay to make sure cookie consent event
            // is already received by GTM.
            setTimeout(() => {
              pushWebVitals(event);
            }, 500);
          });

          // Remove locally stored items.
          sessionStorageRemoveItem('cw_web_vitals');
        } else {
          // No locally stored metrics, push the new metric directly to
          // the datalayer. It is a normal case for the majority of users
          // (with cookie accepted), so no delay is needed.
          pushWebVitals(event);
        }
      }
      // If consent has been declined (no consent for statistics cookies),
      // remove locally stored web vitals.
      else {
        sessionStorageRemoveItem('cw_web_vitals');
      }
    } else {
      // If consent neither given nor declined, add to local storage.
      webVitalsAddToStorage(event);
    }
  }
  // Called on cookie consent (case 2).
  else {
    // Making sure cooking consent is given.
    if (
      typeof Cookiebot !== 'undefined' &&
      Cookiebot.hasResponse &&
      Cookiebot.consent &&
      Cookiebot.consent.statistics
    ) {
      const localEvents = sessionStorageGetItem('cw_web_vitals');
      if (!localEvents) {
        return;
      }
      const events = localEvents ? JSON.parse(localEvents) : [];
      events.forEach((event) => {
        // Adding a small delay to make sure cookie consent event
        // is already received by GTM.
        setTimeout(() => {
          pushWebVitals(event);
        }, 500);
      });

      sessionStorageRemoveItem('cw_web_vitals');
    }
  }
}
