/* eslint-disable no-undef */

/**
 * Delete cookiebot consent older than 6 months
 * and show a new cookiebot banner / popup.
 * See https://www.pivotaltracker.com/story/show/174086049.
 */
export const cookieBotConsentExpirationCheck = () => {
  if (typeof window === 'undefined') {
    return;
  }

  window.addEventListener('CookiebotOnLoad', () => {
    const consentDate = new Date(CookieConsent.consentUTC);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    // Compare dates and delete the CookieConsent cookie
    // if it was set six months ago and display the banner to get fresh consent.
    if (consentDate < sixMonthsAgo) {
      CookieConsent.deleteConsentCookie();
      Cookiebot.show();
    }
  });
};
