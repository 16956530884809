const Router = require('next/router').default;
const { parse } = require('url');
const debug = require('debug')('cw:routing');
const fetch = require('../utils/fetch.js');
const { matchAppOnlyRoute, toQuerystring } = require('./routes');
const { toast } = require('react-toastify');

/**
 * Return parameters to apply to the <Link> component of
 * Next.js to make the routing working properly.
 */
const getEntityURL = (entity) => {
  const entityURL = {
    // Value which ALWAYS represents client facing URL that you see in the browser.
    // If it's null, then something went wrong and this entity does not have URL.
    url: null,

    // Value which ALWAYS represents internal Next.js route for the current
    // entity. If it's null, then the url should be treated as external.
    route: null,

    // Info if the link is considered as external by Drupal or not.
    isExternal: false,

    // The next two params are internal route + human readable URL for
    // Next.js <Link /> component. It's expected to pass the result of this
    // function to the link just like that <Link {...entityURL} /> to make
    // it render the link with the right internal routing.
    // Props from above "url" and "route" will be ignored by the component.
    // If you add here more <Link /> props they will be automatically
    // applied inside of Link components.
    href: null,
    as: null,
    // Title for corresponding entity. We can use title as a data-title
    // attribute for a link for analytic purposes.
    title: null,
  };

  if (!entity || !entity.hasOwnProperty('url')) {
    return entityURL;
  }

  // It's assumed that every entity has url property passed from the backend
  // as an object with URL and it's metadata.
  const { url } = entity;

  // If URL is empty obviously there is nothing we can do anymore here.
  if (!(url && url.hasOwnProperty('url'))) {
    return entityURL;
  }

  // Copy URL value into the object. It is always client facing URL.
  entityURL.url = url.url;

  // Default href to the url value for <Link> rendering properly.
  entityURL.href = entityURL.url;

  // Add entity title to result object.
  entityURL.title = url.entity_title || '';

  // If the URL is external then we're done here, no need to modify the
  // object any further.
  if (url.hasOwnProperty('is_external') && url.is_external) {
    entityURL.isExternal = true;
    return entityURL;
  }

  url.entity_type = url.entity_type || '';
  url.entity_bundle = url.entity_bundle || '';

  // If the URL object from the backend has metadata regarding entity type / bundle
  // then we can build the internal page route based on that.
  if (url.entity_type !== '' && url.entity_bundle !== '') {
    const route = `/${url.entity_type}/${url.entity_bundle}`;
    entityURL.route = route;
    entityURL.href = route;
    entityURL.as = entityURL.url;
  } else {
    // If the path does not have a corresponding entity on the backend it still
    // can be an internal route which exists in the frontpage app only.
    const route = matchAppOnlyRoute(entityURL.url);
    if (route) {
      entityURL.route = route.href;
      entityURL.href = `${route.href}${route.query ? `?${toQuerystring(route.query)}` : ''}`;
      entityURL.as = entityURL.url;
    }
  }

  return entityURL;
};

/**
 * Returns the link object to the homepage.
 */
const getHomepageLink = (settings, isRaw = false) => {
  const globalSettings = settings || {};

  // Link to the front page of the website.
  if (
    globalSettings.field_frontpage &&
    typeof globalSettings.field_frontpage === 'object' &&
    typeof globalSettings.field_frontpage[0] === 'object'
  ) {
    const nextLink = getEntityURL(globalSettings.field_frontpage[0]);
    if (nextLink.url) {
      // Normally the homepage link should have "/" as the URL. However,
      // there are several cases in the routing when we need to get the
      // "unmasked" url of the node on the Drupal for the home page. "isRaw"
      // flag enables the behavior of keeping the original node URL instead of
      // masking it.
      if (!isRaw) {
        nextLink.url = '/';
        nextLink.as = '/';
      }
      return nextLink;
    }
  }

  return null;
};

/**
 * Returns homepage breadcrumb from the global site settings.
 */
const getHomepageBreadcrumb = (homepageLink) => {
  if (homepageLink) {
    return { label: 'Home', nextLink: homepageLink };
  }

  return null;
};

/**
 * Transforms requested path to the corresponding API path.
 */
function normalizeRequestedPath(requestedPath) {
  // In most cases requested path is used for API request as is, without any
  // modifications .One exception is donation form checkout flow.
  // All checkout pages use parent appeal page for rendering because their
  // configuration is stored on appeal node level.
  // TODO: add request listener on backend to redirect to appeal page endpoint
  // only if /checkout suffix was added to a valid appeal page alias.
  const checkoutPatternMatches = /(.+?)\/checkout($|\/)/i.exec(requestedPath);
  if (checkoutPatternMatches !== null) {
    return checkoutPatternMatches[1];
  }

  return requestedPath;
}

/**
 * Return Drupal entity URL which can be requested from the backend.
 */
function getNormalizedEntityURL(requestedURL, homepageLink, res = null) {
  // Get object with different parts of the URL.
  const parsedUrl = parse(requestedURL, true);

  // Normalize the requested path.
  let entityURL = normalizeRequestedPath(parsedUrl.pathname);

  // If the user requested page which is configured on the backend to be
  // the homepage of the application, then we need to force redirect him
  // to the homepage instead.
  if (homepageLink && parsedUrl.pathname === homepageLink.url) {
    debug(
      'The requested page %s is an alias of the front page. Redirecting to the front page.',
      homepageLink.url
    );

    let homepageURL = '/';
    if (parsedUrl.search) {
      homepageURL = `${homepageURL}${parsedUrl.search}`;
    }

    if (res) {
      // Server level redirect.
      res.redirect(301, homepageURL);
    } else {
      // Client level redirect.
      Router.push(homepageLink.route, homepageURL);
    }

    return null;
  }

  // The homepage on the Drupal backend is not "/" but some other alias,
  // so in case of the front page we need to request the node with the right alias.
  if (homepageLink && parsedUrl.pathname === '/') {
    debug(
      'Home page requested. Using %s page in Drupal for the homepage as defined in global settings.',
      homepageLink.url
    );
    entityURL = homepageLink.url;
  }

  return entityURL;
}

/**
 * Returns response from Drupal entity loaded via API.
 */
async function getDrupalEntityFromRoute(url, bypassCDNCache = false, previewToken = '') {
  debug('Requesting data for the URL %s from Drupal backend...', url);
  const query = {
    _format: 'json_recursive',
    path: url,
    language: 'en',
  };

  // A special param that bypasses Cloudflare's CDN cache.
  if (bypassCDNCache) {
    query._nocdncache = '_nocdncache'; // eslint-disable-line no-underscore-dangle
  }

  // A special token that allows anyone to view a certain
  // node revision bypassing access checks.
  if (previewToken) {
    query.previewToken = previewToken;
  }

  return fetch('/decoupled-router', { query, throwNotOk: false, timeout: 60000 });
}

/**
 * Makes necessary checks to make sure that entity response from API
 * is valid (throw error / redirect otherwise) and return content
 * of the entity it all checks are fine.
 */
function validateAndProcessPageContentResponse(response, requestedURL, res = null) {
  const props = {
    statusCode: null,
    entity: null,
    entityURL: null,
  };

  // Close all opened Alerts if user switched page.
  toast.dismiss();

  // Catch all 40x and 50x errors during request to fetch entity data for the given url.
  if (response.statusCode >= 400) {
    if (res) res.status(response.statusCode);
    props.statusCode = response.statusCode;
    debug('Entity request to the backend caught %s response code.', response.statusCode);
    return props;
  }

  // Grab status code and content from the Drupal response.
  let { statusCode } = response.body;
  const { content } = response.body;

  // If we received a redirect response then we pass the status code
  // and entityUrl and server will handle it, see routing/server.js
  statusCode = Number.parseInt(statusCode, 10);
  if (statusCode >= 300 && statusCode < 400) {
    props.statusCode = statusCode;
    props.entityURL = { url: content };
    return props;
  }

  // Grab API response containing entity's object.
  props.entity = content;
  props.entityURL = getEntityURL(props.entity);

  // If entity URL object does not have URL or internal next.js route,
  // then we can't render the page.
  if (!(props.entityURL.url && props.entityURL.route)) {
    debug('Entity route values are empty for URL %s', requestedURL);
    debug('Entity route values are empty. Response from Drupal: %o', response.body);
    if (res) {
      res.status(502).end('Internal Routing Error.');
    }
    props.statusCode = 502;
    return props;
  }

  // The type of request we do to the backend to determine if the url
  // exists on the backend automatically follows 301/302 redirects. So
  // if that happened, then entity path alias will be different from what
  // the user originally requested, which means that we need to redirect
  // them to the right path alias.
  if (requestedURL !== props.entityURL.url && requestedURL !== '/') {
    if (!res) {
      // Perform redirect to the canonical URL if the original URL is different.
      debug(
        'Entity URL does not match the requested URL. Redirecting from %s to %s.',
        requestedURL,
        props.entityURL.url
      );
      // Client level redirect.
      Router.push(props.entityURL.route, props.entityURL.url);
    } else {
      // Just pass the status code - server will handle it, see
      // routing/server.js
      props.statusCode = 301;
    }

    return props;
  }

  // Helper message to understand the entry point for the page render.
  debug(
    'All good, building page using ./pages%s.js file as an entry point.',
    props.entityURL.route
  );

  props.statusCode = 200;
  return props;
}

/**
 * Returns array with breadcrumb navigation for the given entity.
 */
const getEntityBreadcrumb = (entity, navigation, homepageLink) => {
  const entityURL = getEntityURL(entity);

  // Build breadcrumb navigation.
  const breadcrumbs = [];
  let isFirstBreadcrumb = false;

  // Recursively loop through the primary navigation and build breadcrumbs based
  // on the given page URL, stopping at the first occurrence of the current page in the menu.
  const buildBreadcrumbs = (url, items, breadcrumbList = []) => {
    let hasMatch = false;

    if (isFirstBreadcrumb) {
      return null;
    }

    items.forEach((item) => {
      let hasMatchedChild = false;

      if (item.children) {
        hasMatchedChild = buildBreadcrumbs(url, item.children, breadcrumbList);
      }

      const matches = item.url.href === url.href && item.url.as === url.as;

      if (matches || hasMatchedChild) {
        hasMatch = true;
        breadcrumbList.unshift({ label: item.title, nextLink: item.url });
      }

      if (matches) {
        isFirstBreadcrumb = true;
      }
    });

    return hasMatch;
  };

  buildBreadcrumbs(entityURL, navigation, breadcrumbs);

  // Remove top level navigation (drop-down menus).
  // See https://www.pivotaltracker.com/story/show/164461757/comments/201750687.
  breadcrumbs.shift();

  // Remove current page breadcrumb
  breadcrumbs.pop();

  // Get homepage link and add it as the first breadcrumb.
  const homepageBreadcrumb = getHomepageBreadcrumb(homepageLink);
  if (homepageBreadcrumb) {
    breadcrumbs.unshift(homepageBreadcrumb);
  }

  return breadcrumbs;
};

module.exports = {
  getHomepageLink,
  getEntityURL,
  getEntityBreadcrumb,
  getNormalizedEntityURL,
  getDrupalEntityFromRoute,
  validateAndProcessPageContentResponse,
};
