const config = require('next/config').default();

let BACKEND_URL = '';
let FRONTEND_CNET_URL = '';
let FRONTEND_GIFTS_URL = '';
let ENVIRONMENT = '';
let DEFAULT_COUNTRY_CODE = '';
let RECAPTCHA_SITE_KEY = '';
let CONSUMER_ID = '';
let GET_ADDRESS_API_KEY = '';
let AUTO_ADDRESS_API_KEY = '';
let AUTO_ADDRESS_GIFTS_API_KEY = '';
let ROLLBAR_CLIENT_ACCESS_TOKEN = '';
let APP_BUILD_ID = '';
let COOKIEBOT_ID = '';
let ELASTICSEARCH_URL = '';
let ELASTICSEARCH_CREDENTIALS = '';
let ELASTICSEARCH_INDEX_PREFIX = '';

// Normally we can take the values from next.js config. However,
// if the constants are used on the very early stage of the application,
// then we must take the values from the global environment variables of the container.
if (config && config.publicRuntimeConfig) {
  ({
    BACKEND_URL,
    FRONTEND_CNET_URL,
    FRONTEND_GIFTS_URL,
    ENVIRONMENT,
    DEFAULT_COUNTRY_CODE,
    RECAPTCHA_SITE_KEY,
    GET_ADDRESS_API_KEY,
    AUTO_ADDRESS_API_KEY,
    AUTO_ADDRESS_GIFTS_API_KEY,
    CONSUMER_ID,
    ROLLBAR_CLIENT_ACCESS_TOKEN,
    APP_BUILD_ID,
    COOKIEBOT_ID,
    ELASTICSEARCH_URL,
    ELASTICSEARCH_CREDENTIALS,
    ELASTICSEARCH_INDEX_PREFIX,
  } = config.publicRuntimeConfig);
} else if (process && process.env) {
  ({
    BACKEND_URL,
    FRONTEND_CNET_URL,
    FRONTEND_GIFTS_URL,
    ENVIRONMENT,
    DEFAULT_COUNTRY_CODE,
    RECAPTCHA_SITE_KEY,
    GET_ADDRESS_API_KEY,
    AUTO_ADDRESS_API_KEY,
    AUTO_ADDRESS_GIFTS_API_KEY,
    CONSUMER_ID,
    ROLLBAR_CLIENT_ACCESS_TOKEN,
    APP_BUILD_ID,
    COOKIEBOT_ID,
    ELASTICSEARCH_URL,
    ELASTICSEARCH_CREDENTIALS,
    ELASTICSEARCH_INDEX_PREFIX,
  } = process.env);
}

/**
 * Single donation type.
 */
const SD = 'single_donation';

/**
 * Recurring donation type.
 */
const RG = 'recurring_donation';

/**
 * Each step must have a machine name (object key).
 * In edge cases when you need to refer to the step using integer, use stepId.
 */
const DONATION_FORM_STEPS_BY_REGION = {
  IE: {
    amount: { path: '/checkout', stepId: 1, stepIndicatorName: 'Name & email' },
    address: { path: '/checkout/address', stepId: 2, stepIndicatorName: 'Address' },
    preferences: { path: '/checkout/preferences', stepId: 3 },
    payment: { path: '/checkout/payment', stepId: 5, stepIndicatorName: 'Payment' },
    'thank-you': { path: '/checkout/thank-you', stepId: 6 },
  },
  GB: {
    amount: { path: '/checkout', stepId: 1, stepIndicatorName: 'Name & email' },
    address: { path: '/checkout/address', stepId: 2, stepIndicatorName: 'Address' },
    preferences: { path: '/checkout/preferences', stepId: 3 },
    giftaid: { path: '/checkout/giftaid', stepId: 5 },
    payment: { path: '/checkout/payment', stepId: 6, stepIndicatorName: 'Payment' },
    'thank-you': { path: '/checkout/thank-you', stepId: 7 },
  },
};
const DONATION_FORM_STEPS = DONATION_FORM_STEPS_BY_REGION[DEFAULT_COUNTRY_CODE];

const DONATION_TYPES_PATH_ALIAS = {
  [SD]: 'single',
  [RG]: 'monthly',
};

const CATEGORIES = [
  { value: 'disaster-risk-reduction', label: 'Disaster Risk Reduction' },
  { value: 'graduation', label: 'Graduation' },
  { value: 'innovations', label: 'Innovations' },
  { value: 'learning', label: 'Learning' },
  { value: 'gender-equality', label: 'Gender Equality' },
  { value: 'conservative-agriculture', label: 'Conservation Agriculture' },
  { value: 'monitoring-and-evaluation', label: 'Monitoring and Evaluation' },
];

const SUGGESTED_SEARCHES_TITLE = 'Explore';

/* Inspired by https://stackoverflow.com/a/46181/4853655. */
/* eslint-disable-next-line no-useless-escape */
const EMAIL_EXPRESSION =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LOQATE_ENDPOINT_IBAN =
  'https://api.addressy.com/InternationalBankValidation/Interactive/Validate/v1.00/json3.ws';
const LOQATE_ENDPOINT_UK =
  'https://api.addressy.com/BankAccountValidation/Interactive/Validate/v2.00/json3.ws';

const PAGE_LAYOUTS = {
  '/node/appeal': 'centered',
  '/node/country': 'twoColumns',
  '/node/news': 'centered',
  '/node/page': 'centered',
  '/node/press_release': 'centered',
  '/node/publication': 'centered',
  '/node/publication_landing': 'left',
  '/node/regular_giving_upgrades_campaign': 'twoColumns',
  '/node/section_index_page': 'twoColumns',
  '/node/standard_info_page': 'twoColumns',
  '/node/story': 'centered',
};

// We need to know Knowldgge hub URL to be able to generate URLs for
// related categories on the Resource page.
// TODO: Move it to the site settings or think how to avoid it at all.
const KNOWLEDGE_HUB_URL = '/knowledge-hub';

module.exports = {
  BACKEND_URL,
  FRONTEND_CNET_URL,
  FRONTEND_GIFTS_URL,
  ENVIRONMENT,
  DEFAULT_COUNTRY_CODE,
  SD,
  RG,
  DONATION_FORM_STEPS,
  DONATION_TYPES_PATH_ALIAS,
  CATEGORIES,
  SUGGESTED_SEARCHES_TITLE,
  EMAIL_EXPRESSION,
  RECAPTCHA_SITE_KEY,
  GET_ADDRESS_API_KEY,
  AUTO_ADDRESS_API_KEY,
  AUTO_ADDRESS_GIFTS_API_KEY,
  CONSUMER_ID,
  LOQATE_ENDPOINT_IBAN,
  LOQATE_ENDPOINT_UK,
  ROLLBAR_CLIENT_ACCESS_TOKEN,
  APP_BUILD_ID,
  COOKIEBOT_ID,
  PAGE_LAYOUTS,
  ELASTICSEARCH_URL,
  ELASTICSEARCH_CREDENTIALS,
  ELASTICSEARCH_INDEX_PREFIX,
  KNOWLEDGE_HUB_URL,
};
