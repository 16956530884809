const isomorphicFetch = require('isomorphic-unfetch');
const cache = require('memory-cache');
const { toQuerystring } = require('../routing/routes');
const rollbar = require('./rollbar');

const debug = require('debug')('cw:fetch');

function logger(error, response, url) {
  if (error) {
    if (rollbar) {
      if (typeof window !== 'undefined') {
        // Errors on the client side.
        rollbar.error(
          `Client: Error during request to ${url}`,
          `User location: ${window.location.href}`,
          { error },
        );
      } else {
        // We decided to send only 3 errors within a minute in rollbar in case when
        // backend isn't available to not rich Rollbar limit per minute .
        const storageVariableName = 'count_last_server_error_during_request_to_backend';
        const now = Date.now();
        const lastErrors = cache.get(storageVariableName);
        if (!lastErrors || (lastErrors && lastErrors.count < 3)) {
          rollbar.error(`Server: Error during request to ${url}`, { error });
          if (lastErrors) {
            cache.put(
              storageVariableName,
              { count: lastErrors.count + 1, expire: lastErrors.expire },
              +(lastErrors.expire - now),
            );
          } else {
            cache.put(storageVariableName, { count: 1, expire: now + 60 * 1000 }, 60 * 1000);
          }
        }
      }
    }

    return;
  }

  // Add rollbar logging for all failed requests to the backend.
  let condition =
    response.statusCode >= 400 && response.statusCode !== 404 && response.statusCode !== 414;
  if (typeof window !== 'undefined') {
    // On the client side we are interested in 404 errors as well.
    condition = response.statusCode >= 400;
  }

  if (rollbar && condition) {
    rollbar.error(`${response.statusCode} error during request to the ${url}`, {
      response,
    });
  }

  return response;
}

/**
 * This is universal (frontend & backend) fetch initializer.
 */
const fetch = (path, options = {}) => {
  // See https://github.com/vercel/next.js/issues/5817#issuecomment-526876799
  // eslint-disable-next-line global-require
  const config = require('next/config').default();

  if (config === undefined) {
    return undefined;
  }
  const {
    publicRuntimeConfig: {
      BACKEND_URL,
      CONSUMER_ID,
      PAYMENT_SECRET_HEADER_NAME,
      TEST_SUITE_SECRET_HEADER_NAME,
    },
    serverRuntimeConfig: { HTTP_AUTH_USER, HTTP_AUTH_PASS },
  } = config;

  let isExternal = false;
  let url = path;
  // If the path doesn't start with http, append backend url.
  if (!/^https?:\/\//.test(url)) {
    url = `${BACKEND_URL}${url}`;
  } else if (!path.startsWith(BACKEND_URL)) {
    isExternal = true;
  }

  const defaultHeaders = {
    Accept: 'application/json',
  };
  // If the current environment includes http auth variables, then include them
  // as a custom header into the request.
  if (!isExternal && HTTP_AUTH_USER && HTTP_AUTH_PASS) {
    defaultHeaders['HTTP-Auth'] = `${HTTP_AUTH_USER}:${HTTP_AUTH_PASS}`;
  }
  // Set payment secret header value from browser session storage to enable test
  // payment mode.
  if (!isExternal && PAYMENT_SECRET_HEADER_NAME) {
    try {
      const secret = window.sessionStorage.getItem(PAYMENT_SECRET_HEADER_NAME);
      if (secret) {
        defaultHeaders[PAYMENT_SECRET_HEADER_NAME] = secret;
      }
    } catch (e) {
      // Access denied - localStorage is disabled.
    }
  }

  // If this is the test suite then add this info to the backend requests.
  if (!isExternal && TEST_SUITE_SECRET_HEADER_NAME) {
    try {
      const secret = window.sessionStorage.getItem(TEST_SUITE_SECRET_HEADER_NAME);
      if (secret) {
        defaultHeaders[TEST_SUITE_SECRET_HEADER_NAME] = secret;
      }
    } catch (e) {
      // Access denied - localStorage is disabled.
    }
  }

  const headers = {
    ...defaultHeaders,
    ...(options.headers || {}),
  };
  const defaultQuery = {};
  if (!isExternal && CONSUMER_ID) {
    defaultQuery.consumerId = CONSUMER_ID;
  }
  const query = {
    ...defaultQuery,
    ...(options.query || {}),
  };
  const requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'same-origin',
    throwNotOk: true,
    timeout: 59000,
    ...options,
    headers,
  };
  const queryString = toQuerystring(query);
  url = `${url}${queryString ? `?${queryString}` : ''}`;

  // Automatically convert data objects in JSON string.
  if (requestOptions.body && typeof requestOptions.body === 'object') {
    requestOptions.body = JSON.stringify(options.body);
  }

  // See https://stackoverflow.com/a/57888548.
  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
    debug('Fetch request aborted after %s milliseconds', requestOptions.timeout);
  }, requestOptions.timeout);
  requestOptions.signal = controller.signal;

  debug('Fetch request params:', url);

  const responsePromise = isomorphicFetch(url, requestOptions)
    .then((response) => {
      logger(null, response, url);

      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then((json) => {
          return {
            body: json,
            isJSON: true,
            isPlainText: false,
            statusCode: response.status,
            status: response.status,
            ok: response.ok,
          };
        });
      } else {
        return response.text().then((text) => {
          return {
            body: text,
            isJSON: false,
            isPlainText: true,
            statusCode: response.status,
            status: response.status,
            ok: response.ok,
          };
        });
      }
    })
    .then((response) => {
      if (requestOptions.throwNotOk && !response.ok) {
        let message =
          response.body && response.body.message ? response.body.message : response.body;
        throw `${response.statusCode} ${message ? message : 'error'}`;
      }

      return response;
    })
    .catch((error) => {
      logger(error, null, url);
      throw error;
    });

  return responsePromise.finally(() => clearTimeout(timeout));
};

module.exports = fetch;
