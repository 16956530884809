import { DEFAULT_CURRENCY } from './currency';
import rollbar from './rollbar';

/* global dataLayer */

/**
 * The wrapper to do pushing GTM events by more reliable way.
 */
export const pushGtmEvent = (data) => {
  const isOnline = navigator.onLine;

  const waitForOnline = () => {
    const eventHandler = () => {
      window.removeEventListener('online', eventHandler);

      const isDataLayerLoaded = typeof dataLayer === 'object';

      // The variable dataLayer must be defined for all the cases (we do it manually).
      // So, theoretically we will not have the cases when it isn't defined, but to make
      // sure, log that problem.
      if (!isDataLayerLoaded) {
        if (rollbar) {
          rollbar.error(
            'DataLayer is not detected (after becoming online). It should not have happened!',
          );
        }
        return;
      }

      // Push.
      setTimeout(() => dataLayer.push(data), 500);
    };

    window.addEventListener('online', eventHandler);
  };

  // dataLayer.push doesn't have ways to check was it actually sent or not.
  // Let's check "navigator.onLine" before attempting to send GTM events,
  // theoretically it should increase reliability of this process.
  // Actually GTM library pushes the events when they appear in dataLayer,
  // but GTM library doesn't care about result of HTTP request that pushes
  // events. Let's do it only when we online.
  if (!isOnline) {
    waitForOnline();
    return;
  }

  const isDataLayerLoaded = typeof dataLayer === 'object';

  // The variable dataLayer must be defined for all the cases (we do it manually).
  // So, theoretically we will not have the cases when it isn't defined, but to make
  // sure, log that problem.
  if (!isDataLayerLoaded) {
    if (rollbar) {
      rollbar.error(
        'DataLayer is not detected (after becoming online). It should not have happened!',
      );
    }
    return;
  }

  // Push.
  dataLayer.push(data);
};

/**
 * We can't track page views very well by GTM in SPA, so
 * we have to send custom pageview event on every routeChangeComplete.
 */
export const pushPageview = () => {
  // Get or initialize global Cnet variable.
  window.Cnet = window.Cnet || {};
  window.Cnet.previousPath = window.Cnet.previousPath || null;

  // Workaround for https://github.com/zeit/next.js/issues/6025
  setTimeout(() => {
    // Workaround with previous path, because when location changes too quickly
    // (eg. automatic redirect from /checkout to /checkout/single)
    // because of delay in 1 second, it always pushes latest pathname
    // (eg. it pushes `/checkout/single` two times in this case)
    // eslint-disable-next-line no-undef, no-restricted-globals
    if (window.Cnet.previousPath !== location.pathname) {
      pushGtmEvent({ event: 'pageview' });
    }

    // eslint-disable-next-line no-undef, no-restricted-globals
    window.Cnet.previousPath = location.pathname;
  }, 1);
};

/**
 * Send a custom form submission event with given form id.
 *
 * We can't track default Gtm Form Submission event because of SPA.
 */
export const pushFormSubmission = (formId = 'undefined') => {
  pushGtmEvent({
    event: 'formSubmission',
    formID: formId,
  });
};

/**
 * Push checkout step to GTM.
 *
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#measuring-checkout
 */
export const pushCheckoutStep = (step = 1, option = '', products = []) => {
  pushGtmEvent({
    event: 'checkout',
    ecommerce: {
      currencyCode: DEFAULT_CURRENCY,
      checkout: {
        actionField: { step, option },
        products,
      },
    },
  });
};

/**
 * Push checkout option to GTM.
 *
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#measuring-checkout
 */
export const pushCheckoutOption = (step = 1, option = '') => {
  pushGtmEvent({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: { step, option },
      },
    },
  });
};

/**
 * Push Product purchase to GTM.
 *
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce#purchases
 */
export const pushPurchase = (transactionData, products = []) => {
  pushGtmEvent({
    event: 'purchase',
    ecommerce: {
      currencyCode: DEFAULT_CURRENCY,
      purchase: {
        actionField: transactionData,
        products,
      },
    },
  });
};

/**
 * Push view_item event to GTM.
 *
 * @see https://developers.google.com/tag-manager/ecommerce-ga4?hl=ru#measure_viewsimpressions_of_productitem_details
 */
export const pushViewItem = (items) => {
  pushGtmEvent({
    event: 'view_item',
    ecommerce: {
      items,
    },
  });
};

/**
 * Push an event if a user actively opted in using at least one channel.
 */
export const pushContactPreferences = (preferences) => {
  // For simplicity, we exclude post from tracking. In the case of UK,
  // donors can optionally opt out from post updates. We ignore it here.
  const preferencesWithoutPost = preferences.filter((item) => item !== 'post');

  if (preferencesWithoutPost.length) {
    pushGtmEvent({
      event: 'optedInDuringCheckout',
      preferences: preferencesWithoutPost,
    });
  }
};

/**
 * Push an event if a user saw contact-preferences, but skipped them.
 */
export const pushSkippedContactPreferences = () => {
  pushGtmEvent({
    event: 'skippedOptinsDuringCheckout',
  });
};

/**
 * Push Money Handle click to GTM.
 */
export const moneyHandleClick = (
  position,
  blockName,
  appealTitle,
  amount,
  donationType,
  preselectedOption,
  impact,
) => {
  // Use existing DL variables gtm.element.dataset.* to avoid new one.
  pushGtmEvent({
    event: 'moneyHandleClick',
    gtm: {
      element: {
        dataset: {
          position,
          amount,
          title: appealTitle,
          category: `${blockName} ${donationType === 'single' ? 'Single' : 'Monthly'}`,
          preselected: preselectedOption || null,
          impact: impact || null,
        },
      },
    },
  });
};

export const pushDonationFormValidationErrors = (step, errors) => {
  // Note that we expose each error separately to simplify configuration on
  // GTM side.
  Object.keys(errors).forEach((field) => {
    if (typeof errors[field] === 'string') {
      pushGtmEvent({
        event: 'donationFormValidationError',
        step,
        field,
        error: errors[field],
      });
    } else {
      // Support 1 extra level of subfields.
      Object.keys(errors[field]).forEach((fieldLevel2) => {
        pushGtmEvent({
          event: 'donationFormValidationError',
          step,
          field: fieldLevel2,
          error: errors[field][fieldLevel2],
        });
      });
    }
  });
};

export const pushDonationFormSubmissionError = (paymentMethod, error) => {
  pushGtmEvent({
    event: 'donationFormSubmissionError',
    step: paymentMethod,
    error,
  });
};

/**
 * Push Santa Letter form validation errors to GTM.
 */
export const pushSantaLetterFormValidationErrors = (errors) => {
  Object.keys(errors).forEach((field) => {
    pushGtmEvent({
      event: 'santaLetterFormValidationError',
      field,
      error: errors[field],
    });
  });
};

/**
 * The function will be called when filters/search/sort for the Knowledge hub
 * functionality is updated.
 *
 * @param data
 */
export const pushKnowledgeHubFiltersUpdated = (data) => {
  // We need to clear value before pushing a new data to avoid
  // combining previous values with the data.
  pushGtmEvent(
    {
      event: 'clearElementDataset',
      element: {
        dataset: undefined,
      },
    },
    {
      event: 'knowledgeHubFiltersUpdated',
      element: {
        dataset: data,
      },
    },
  );
};

/**
 * Push an event when a user get the popup to return to a donation.
 */
export const pushReturnToDonationPopup = () => {
  const displayed = window.sessionStorage.getItem('displayedReturnToDonationPopup');
  if (!displayed) {
    pushGtmEvent({
      event: 'displayedReturnToDonationPopup',
    });
    window.sessionStorage.setItem('displayedReturnToDonationPopup', true);
  }
};

/**
 * Push an event when a user gets the browser prompt and decides to stay.
 */
export const pushReturnToDonationPrompt = () => {
  pushGtmEvent({
    event: 'returnToDonationAfterPrompt',
  });
};

/**
 * The function will be called when a user has changed an active item on slider.
 *
 * @param data
 */
export const pushSliderHasChanged = (data) => {
  pushGtmEvent({
    event: 'slide_has_changed',
    element: {
      dataset: data,
    },
  });
};

/**
 * Push Web Vitals to GTM.
 *
 * Based on https://www.simoahava.com/analytics/track-core-web-vitals-in-ga4-with-google-tag-manager/
 */
export const pushWebVitals = (metric) => {
  pushGtmEvent({
    event: 'coreWebVitals',
    webVitalsMeasurement: metric,
  });
};
