import { all, select, put, takeEvery } from 'redux-saga/effects';
import * as donationAPI from '../../api/donation';
import { DEFAULT_COUNTRY_CODE } from '../../utils/constants';
import {
  donationFormLock,
  donationFormPaymentSuccess,
  donationFormSubmitError,
  donationFormSubmitSuccess,
  donationFormUnLock,
} from '../Slices/cnet/donationFormSlice';

const debug = require('debug')('cw:store:sagas');

/**
 * React on trigger to submit the donation form.
 */
function* formSubmitSaga() {
  try {
    // Inform UI that we process form data.
    yield put(donationFormLock());

    // Grab donation form state from the redux store.
    // Bear in mind that state variable is mutable and you most likely
    // don't want to modify it directly.
    const state = yield select((store) => store.donationForm.form.values);
    yield debug('Submitting donation form. State: %O', state);

    let paymentOptions = { ...state.payment_options };

    // Prepare sort code for UK Direct Debit.
    if (
      DEFAULT_COUNTRY_CODE === 'GB' &&
      state.payment_method.startsWith('direct_debit') &&
      paymentOptions.sort_code !== undefined
    ) {
      paymentOptions = {
        ...state.payment_options,
        sort_code: paymentOptions.sort_code.join(),
      };
    }

    // Do not send paypal/stripe payment data alongside with donation request,
    // because it should be handled separately.
    const paymentMethod = /^(paypal|decoupled_stripe)_/.test(state.payment_method)
      ? ''
      : state.payment_method;

    const internalTags = [];

    const fundraisingCosts = state.fundraisingCostsApplied ? state.fundraisingCostsAmount : 0;
    // Replacing final amount right before submitting.
    const amount = state.fundraisingCostsApplied ? state.totalAmount : state.amount;

    const companyName =
      state.company_donation && state.company_donation[0] === 'company_donation'
        ? state.company_name
        : '';

    const result = yield donationAPI.submitDonation(
      state.appealId,
      state.type,
      amount,
      paymentMethod,
      paymentOptions || {},
      state.source_code || '',
      state.first_name || '',
      state.last_name || '',
      state.email || '',
      state.phone || '',
      state.contact_preferences || [],
      state.address || {},
      state.giftaid || false,
      state.dsn || '',
      state.viewedPreferencesOptions || [],
      state.school_name || '',
      state.captcha_token || '',
      internalTags || [],
      fundraisingCosts,
      companyName,
    );

    // Debug response from the donation form submission.
    debug('Result of donation submission: %O', result);

    if (result.statusCode === 201) {
      yield put(donationFormSubmitSuccess(result.orderID));
      if (result.orderStatus === 'completed') {
        yield put(donationFormPaymentSuccess(result.orderID));
      }
    } else {
      debug('Could not submit donation form. Error: ', result.errorMessage);
      // The form can be edited again.
      yield put(donationFormUnLock());
      yield put(donationFormSubmitError({ errorMessage: result.errorMessage }));
    }
  } catch (e) {
    debug('Could not submit donation form. Error: %O', e);
    // The form can be edited again.
    yield put(donationFormUnLock());
    yield put(
      donationFormSubmitError({
        errorMessage:
          "Sorry, we couldn't submit the form. Please review your payment details and try again.",
      }),
    );
  }
}

/**
 * Main entry point for all donation form sagas.
 */
export default function* donationFormSagas() {
  yield all([yield takeEvery('donationForm/donationFormSubmit', formSubmitSaga)]);
}
