const getDefaultPictureBreakpoints = () => ({
  'min-xs': 'all',
  ss: 'all and (min-width: 360px)',
  ms: 'all and (min-width: 480px)',
  xs: 'all and (min-width: 576px)',
  'max-sm': 'all and (max-width: 767px)',
  sm: 'all and (min-width: 768px)',
  'only-sm': 'all and (min-width: 768px) and (max-width: 991px)',
  'max-md': 'all and (max-width: 991px)',
  md: 'all and (min-width: 992px)',
  'only-smx': 'all and (min-width: 768px) and (max-width: 1199px)',
  'max-lg': 'all and (max-width: 1199px)',
  lg: 'all and (min-width: 1200px)',
  xl: 'all and (min-width: 1520px)',
  ll: 'all and (min-width: 1920px)',
});

const getDefaultPreloadBreakpoints = () => ({
  'min-xs': { min: 'all' },
  ss: { min: 'all and (min-width: 360px)', max: '(max-width: 359px)' },
  ms: { min: 'all and (min-width: 480px)', max: '(max-width: 479px)' },
  xs: { min: 'all and (min-width: 576px)', max: '(max-width: 575px)' },
  'max-sm': { min: 'all and (min-width: 768px)', max: '(max-width: 767px)' },
  sm: { min: 'all and (min-width: 768px)', max: '(max-width: 767px)' },
  'only-sm': { min: 'all and (min-width: 768px)', max: '(max-width: 991px)' },
  'max-md': { min: 'all and (min-width: 992px)', max: '(max-width: 991px)' },
  md: { min: 'all and (min-width: 992px)', max: '(max-width: 991px)' },
  'only-smx': { min: 'all and (min-width: 768px)', max: '(max-width: 1199px)' },
  'max-lg': { min: 'all and (min-width: 1200px)', max: '(max-width: 1199px)' },
  lg: { min: 'all and (min-width: 1200px)', max: '(max-width: 1199px)' },
  xl: { min: 'all and (min-width: 1520px)', max: '(max-width: 1519px)' },
  ll: { min: 'all and (min-width: 1920px)', max: '(max-width: 1919px)' },
});

const addPicturePreload = (picture, defaultIgnoreBreakpoints) => {
  const ignoreBreakpoints = defaultIgnoreBreakpoints || [];
  const preload = [];
  const availableBreakpoints = getDefaultPictureBreakpoints();
  const keys = Object.keys(availableBreakpoints);
  const preloadBreakpoints = getDefaultPreloadBreakpoints();

  if (!picture.sources) {
    return picture;
  }

  // TODO: Add support to preload other types of images.
  // Currently we support preload only for WebP format.
  const sources = picture.sources.filter((source) => source.type === 'image/webp');

  if (!sources) {
    return picture;
  }

  let previousBreakpoint = '';
  sources.forEach((source, index) => {
    const breakpoint = keys.find((key) => availableBreakpoints[key] === source.media);
    const newPreload = {
      type: source.type,
      imageSrcSet: source.srcSet,
      href: picture.fallbackSrc,
    };

    if (index === 0) {
      newPreload.media = source.media;
      preload.push(newPreload);
    } else if (breakpoint.startsWith('max-')) {
      const nexBreakpoint = keys.find(
        (key) => availableBreakpoints[key] === sources[index + 1].media
      );
      newPreload.media = `${preloadBreakpoints[nexBreakpoint].min} and ${preloadBreakpoints[breakpoint].max}`;
      // TODO: Add support for 'max-sm', 'max-md', 'max-lg' breakpoints.
      // If such breakpoints configured, it means they can overlap default breakpoints.
      // Think how to solve double preloading in such cases.
      ignoreBreakpoints.push(breakpoint);
    } else if (breakpoint.startsWith('only-')) {
      newPreload.media = `${preloadBreakpoints[breakpoint].min} and ${preloadBreakpoints[breakpoint].max}`;
      // TODO: Add support for 'only-sm' and 'only-smx' breakpoints.
      // If such breakpoints configured, it means they can overlap default breakpoints.
      // Think how to solve double preloading in such cases.
      ignoreBreakpoints.push(breakpoint);
    } else {
      newPreload.media = `${preloadBreakpoints[breakpoint].min} and ${preloadBreakpoints[previousBreakpoint].max}`;
    }

    if (!ignoreBreakpoints.includes(breakpoint)) {
      preload.push(newPreload);
    }

    previousBreakpoint = breakpoint;
  });

  if (!preload.length) {
    return picture;
  }

  return {
    ...picture,
    preload,
  };
};

module.exports = {
  getDefaultPictureBreakpoints,
  getDefaultPreloadBreakpoints,
  addPicturePreload,
};
