import React from 'react';
import PropTypes from 'prop-types';
import IconApplePay from '../../../../../../public/static/icons/applepay-logo.svg';
import IconGooglePay from '../../../../../../public/static/icons/googlepay-logo.svg';
import IconPayPal from '../../../../../../public/static/icons/paypal-full.svg';
import IconCreditCard from '../../../../../../public/static/icons/credit-card.svg';
import IconDirectDebit from '../../../../../../public/static/icons/direct-debit.svg';
import IconRevolut from '../../../../../../public/static/icons/revolut.svg';

import styles from './index.module.scss';

/**
 * Direct debit payment icon.
 */
const DirectDebitIcon = ({ isActive }) => (
  <div className={`${styles['direct-debit-icon']} ${isActive ? 'active' : ''}`}>
    <IconDirectDebit />
    <div aria-hidden="true">Bank account</div>
  </div>
);
DirectDebitIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

/**
 * Credit card payment icon.
 */
const CreditCardIcon = ({ isActive }) => (
  <div className={`${styles['credit-card-icon']} ${isActive ? 'active' : ''}`}>
    <IconCreditCard />
    <div aria-hidden="true">
      Debit
      <span className="d-md-none" aria-hidden="true">
        {' '}
        or{' '}
      </span>
      <span className="d-none d-md-inline">/</span>
      Credit
    </div>
  </div>
);
CreditCardIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

/**
 * PayPal payment icon.
 */
const PayPalIcon = ({ isActive }) => (
  <IconPayPal className={`${styles['paypal-icon']} ${isActive ? 'active' : ''}`} />
);
PayPalIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

/**
 * GooglePay payment icon.
 */
const GooglePayIcon = ({ isActive }) => (
  <IconGooglePay className={`${styles['google-pay-icon']} ${isActive ? 'active' : ''}`} />
);
GooglePayIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

/**
 * ApplePay payment icon.
 */
const ApplePayIcon = ({ isActive }) => (
  <IconApplePay className={`${styles['apple-pay-icon']} ${isActive ? 'active' : ''}`} />
);
ApplePayIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

/**
 * Revolut payment icon.
 */
const RevolutIcon = ({ isActive }) => (
  <IconRevolut className={`${styles['revolut-icon']} ${isActive ? 'active' : ''}`} />
);
RevolutIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export { DirectDebitIcon, CreditCardIcon, PayPalIcon, ApplePayIcon, GooglePayIcon, RevolutIcon };
