import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: { hubSearchIsTouched: false },
  reducers: {
    searchKnowledgeHubTouched(state, action) {
      return {
        ...state,
        hubSearchIsTouched: true,
      };
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { searchKnowledgeHubTouched } = searchSlice.actions;

// Export the slice reducer as the default export
export default searchSlice.reducer;
