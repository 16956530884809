function getRollbar() {
  let rollbar = null;
  if (typeof window === 'undefined') {
    // Initialize rollbar monitoring for Node.js.
    // Note that ROLLBAR_SERVER_ACCESS_TOKEN is always available on dev envs for
    // the source maps integration so we check if it's production explicitly.
    if (
      process.env.ROLLBAR_SERVER_ACCESS_TOKEN &&
      process.env.ENVIRONMENT &&
      process.env.ENVIRONMENT === 'production'
    ) {
      const Rollbar = require('rollbar'); // eslint-disable-line
      rollbar = new Rollbar({
        enabled: true,
        accessToken: process.env.ROLLBAR_SERVER_ACCESS_TOKEN,
        environment: process.env.ENVIRONMENT,
        captureUncaught: true,
        captureUnhandledRejections: true,
      });
    }
  } else if (window.Rollbar) {
    rollbar = window.Rollbar;
  }

  return rollbar;
}

module.exports = getRollbar();
