import { all, select, takeEvery } from 'redux-saga/effects';
import { pushDonationFormValidationErrors, pushDonationFormSubmissionError } from '../../utils/gtm';

function* formValidationErrorsSaga(action) {
  const [step, paymentMethod] = yield select((store) => [
    store.donationForm.form.values.step.name,
    store.donationForm.form.values.payment_method,
  ]);
  // Append payment method to the payment step name.
  const stepWithPayment = step === 'payment' ? `${step}.${paymentMethod}` : step;

  pushDonationFormValidationErrors(stepWithPayment, action.payload);
}

function* formSubmitErrorSaga(action) {
  const paymentMethod = yield select((store) => store.donationForm.form.values.payment_method);
  pushDonationFormSubmissionError(`payment.${paymentMethod}`, action.errorMessage);
}

/**
 * Main entry point for all donation form sagas.
 */
export default function* analyticsSagas() {
  yield all([
    yield takeEvery('donationForm/donationFormStepValidationErrors', formValidationErrorsSaga),
    yield takeEvery('donationForm/donationFormSubmitError', formSubmitErrorSaga),
  ]);
}
