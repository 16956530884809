// Hobble sites served from unauthorised domains
export const clonePrevention = (clientAccessTokenValue, environment) => {
    
    const whiteList = [
        'c3lzdGVtc2VlZC5ob3N0',
        'Y29uY2Vybi5sb2NhbA==',
        'Y29uY2Vybi5uZXQ=',
        'Y29uY2Vybi5vcmcudWs='
    ];

    const isAuthorised = (servingDomain) => {
        let regex;
        let authorisedDomain;
        let isPositiveMatch = whiteList.find((authorisedDomainEncoded) => {
            authorisedDomain = atob(authorisedDomainEncoded);
            regex = new RegExp(authorisedDomain + '$');
            return regex.test(servingDomain)
        });
        return isPositiveMatch;
    }

    if (!isAuthorised(window.location.hostname)) {
        // remove page body - TO BE REINSTATED AFTER INITIAL DEPLOY
        // document.getElementsByTagName('body')[0].innerHTML = "";

        // snippet for logging warnings might have been removed so log directly using API
        if (clientAccessTokenValue) {
            // Note: any text containing name of reporting service is obscured to complicate search
            const message = `${atob('V2Vic2l0ZSBpcyBiZWluZyBzZXJ2ZWQgZnJvbSBhbiB1bmF1dGhvcmlzZWQgZG9tYWluOg==')} ${window.location.hostname || 'No domain specified'}`;
            const reportUrl = atob('aHR0cHM6Ly9hcGkucm9sbGJhci5jb20vYXBpLzEvaXRlbS8=');
            const clientAccessTokenKey = atob('WC1Sb2xsYmFyLUFjY2Vzcy1Ub2tlbg==');

            const options = {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    [clientAccessTokenKey]: clientAccessTokenValue,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    data: {
                        platform: "browser",
                        framework: "browser-js",
                        language: "javascript",
                        level: "warning",
                        environment: environment,
                        body: {
                            message: {
                                body: message
                            }
                        }
                    }
                })
            };
            fetch(reportUrl, options)
                .then(res => res.json())
                .then(res => console.log(res))
                .catch(err => console.error(err));
        }
    }
};