import * as field from '../transforms.fields';

const debug = require('debug')('cw:transform.gifts.data.js');

/**
 * Transforms Gifts pages data.
 */
const pages = async (pages, globalSettings) => {
  const TransformBlocks = (await import('../transforms.blocks')).default;
  const transformBlocks = new TransformBlocks();
  const result = {};
  Object.keys(pages).forEach((id) => {
    const entity = pages[id];
    try {
      const blocks = field.getArrayValue(entity, 'field_blocks');
      result[id] = {
        ...entity,
        title: field.getTextValue(entity, 'title'),
        // Transform paragraphs on the backend into body blocks on the frontend.
        blocks: transformBlocks.transform(entity, blocks, globalSettings, {}),
        metaTags: entity.field_meta_tags,
      };
      delete result[id].field_meta_tags;
      delete result[id].field_blocks;
    } catch (e) {
      debug(`Error during transform for Gifts page with id=${id}. Error: %O`, e);
    }
  });
  return result;
};

/**
 * Add array of related gifts.
 *
 * If relatedGifts field doesn't contain enough gifts we
 * automatically adding needed amount of gifts to result array.
 */
export const getRelatedProductsForGift = (product, allProducts, relatedGifts, countItems) => {
  const needArrayLength = countItems || 3;
  const products = {
    ...allProducts,
  };

  // Filter products. Exclude product itself.
  delete products[product.id];

  // Filter related products.
  let result = [];
  relatedGifts.forEach((id) => {
    if (products[id]) {
      result.push(id);
      delete products[id];
    }
  });

  if (result.length < needArrayLength) {
    let needItems = needArrayLength - result.length;

    const productsFromCategory = Object.values(products).filter((item) => {
      if (!item.categoryIds || !product.categoryIds) {
        return false;
      }

      const overlappedCategories = item.categoryIds.filter(
        (category_id) => product.categoryIds.indexOf(category_id) !== -1,
      ).length;

      // If one of the products has only 1 category than we check
      // that only one category matches.
      if (item.categoryIds.length === 1 || product.categoryIds.length === 1) {
        return overlappedCategories === 1 && !result.includes(item.id);
      }

      // If products have more than 1 category we check that at least 2
      // categories match, because one of the categories most likely will be
      // 'All gifts'.
      return overlappedCategories > 1 && !result.includes(item.id);
    });

    // Add gifts from the same category to result array if we
    // don't have enough length of result array.
    if (productsFromCategory.length > needItems) {
      result = result.concat(productsFromCategory.slice(0, needItems).map((gift) => gift.id));
    } else if (productsFromCategory.length) {
      result = result.concat(productsFromCategory.map((gift) => gift.id));
    }

    // Add random items if we don't have enough length of result array.
    needItems = needArrayLength - result.length;
    if (Object.keys(products).length > needArrayLength && result.length < needArrayLength) {
      const otherProducts = Object.values(products).filter((gift) => !result.includes(gift.id));
      otherProducts.sort(() => 0.5 - Math.random());
      result = result.concat(otherProducts.slice(0, needItems).map((gift) => gift.id));
    }
  } else {
    result = result.slice(0, needArrayLength);
  }

  return result;
};

/**
 * Transforms Gifts data.
 */
const products = async (products, globalSettings) => {
  const TransformBlocks = (await import('../transforms.blocks')).default;
  const transformBlocks = new TransformBlocks();

  const result = {
    gifts: {},
    corporateGifts: {},
    donation: {},
    freeGifts: {},
  };

  Object.keys(products).forEach((id) => {
    const entity = products[id];

    let transformedEntity = {
      id: field.getNumberValue(entity, 'product_id'),
      metaTags: entity.field_metatags,
      title: field.getTextValue(entity, 'title'),
      url: entity.url,
    };

    const bundle = field.getTextValue(entity, 'entity_bundle');
    const type = field.getTextValue(entity, 'field_gift_type');
    const corporateType = field.getTextValue(entity, 'field_gift_corporate_type');

    if (type) {
      transformedEntity.type = `${bundle}__${type}`;
    } else if (corporateType) {
      transformedEntity.type = `${bundle}__${corporateType}`;
    } else {
      transformedEntity.type = bundle;
    }

    let price = {};

    field.getArrayValue(entity, 'variations').forEach((variation) => {
      const variationPrice = field.getArrayValue(variation, 'price')[0];
      price[variationPrice.currency_code] = {
        sku: field.getTextValue(variation, 'sku'),
        amount: variationPrice.number,
        currency: variationPrice.currency_code,
      };

      if (transformedEntity.type === 'gift__donation') {
        price[variationPrice.currency_code].amount = 0;
      }
    });
    transformedEntity.price = price;

    switch (field.getTextValue(entity, 'entity_bundle')) {
      // Gift and Gifts bundle products have almost the same set of fields,
      // therefore we use the same transform for them.
      case 'gifts_bundle':
      case 'gift': {
        const otherFields = {
          isNew:
            new Date(field.getTextValue(entity, 'field_new_till_date')).getTime() >
            new Date().getTime(),
          image: field.getPicture(
            entity,
            'field_gift_media_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
              sm: {
                '1x': 'gifts_width_720',
              },
            },
            'gifts_width_380',
          ),

          annotation: field.getTextValue(entity, 'field_gift_description'),
          shortDescription: field.getTextValue(entity, 'field_short_description'),
          categoryIds: field
            .getArrayValue(entity, 'field_gift_category')
            .map((item) => field.getNumberValue(item, 'tid')),
          ecardActive: field.getBooleanValue(entity, 'field_ecard_status'),
          fieldEcardPreviewBody: field.getTextValue(entity, 'field_ecard_description'),
          ecardPreviewImage: field.getPicture(
            entity,
            'field_ecard_media_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
              sm: {
                '1x': 'gifts_width_720',
              },
            },
            'gifts_width_380',
          ),
          postalCardActive: field.getBooleanValue(entity, 'field_postal_card_status'),
          fieldPostalPreviewBody: field.getTextValue(entity, 'field_postal_card_description'),
          postalPreviewImage: field.getPicture(
            entity,
            'field_postal_card_media_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
              sm: {
                '1x': 'gifts_width_720',
              },
            },
            'gifts_width_380',
          ),
          fieldWeight: field.getNumberValue(entity, 'field_weight'),
          fieldBestSellerWeight: field.getNumberValue(entity, 'field_best_seller_weight'),
          // Field for Bundle.
          giftsInBundle: field
            .getArrayValue(entity, 'field_gifts')
            .map((item) => field.getNumberValue(item, 'product_id')),

          // TODO: Move the fields Body blocks.
          actionDescription: field.getTextValue(entity, 'field_gift_action_description'),
          tileImageUK: field.getPicture(
            entity,
            'field_gift_action_image',
            {
              'min-xs': {
                '1x': 'gifts_tile_image',
              },
            },
            'gifts_tile_image',
          ),
          actionImage: field.getPicture(
            entity,
            'field_gift_action_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
              sm: {
                '1x': 'gifts_width_720',
              },
            },
            'gifts_width_380',
          ),
          description: field.getTextValue(entity, 'field_gift_what_you_get_body'),
          whatYouGetImage: field.getPicture(
            entity,
            'field_gift_what_you_get_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
              sm: {
                '1x': 'gifts_width_720',
              },
            },
            'gifts_width_380',
          ),
        };

        // Body blocks.
        try {
          const blocks = field.getArrayValue(entity, 'field_blocks');
          otherFields.blocks = transformBlocks.transform(entity, blocks, globalSettings, {});
        } catch (e) {
          debug(`Error during transform for Gift Santa letter product with id=${id}. Error: %O`, e);
        }

        transformedEntity = {
          ...transformedEntity,
          ...otherFields,
        };

        if (transformedEntity.type === 'gift__donation') {
          result['donation'][transformedEntity.id] = transformedEntity;
        } else {
          result['gifts'][transformedEntity.id] = transformedEntity;
        }
        break;
      }

      case 'gift_santa_letter': {
        const otherFields = {
          isNew:
            new Date(field.getTextValue(entity, 'field_new_till_date')).getTime() >
            new Date().getTime(),
          image: field.getPicture(
            entity,
            'field_gift_media_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
              sm: {
                '1x': 'gifts_width_720',
              },
            },
            'gifts_width_380',
          ),

          annotation: field.getTextValue(entity, 'field_gift_description'),
          shortDescription: field.getTextValue(entity, 'field_short_description'),
          categoryIds: field
            .getArrayValue(entity, 'field_gift_category')
            .map((item) => field.getNumberValue(item, 'tid')),
          fieldWeight: field.getNumberValue(entity, 'field_weight'),
          fieldBestSellerWeight: field.getNumberValue(entity, 'field_best_seller_weight'),
          landingPageUrl: field.getLinkValue(entity, 'field_link'),
        };

        // Body blocks.
        try {
          const blocks = field.getArrayValue(entity, 'field_blocks');
          otherFields.blocks = transformBlocks.transform(entity, blocks, globalSettings, {});
        } catch (e) {
          debug(`Error during transform for product with id=${id}. Error: %O`, e);
        }

        transformedEntity = {
          ...transformedEntity,
          ...otherFields,
        };

        result['gifts'][transformedEntity.id] = transformedEntity;
        break;
      }

      case 'gift_corporate': {
        const otherFields = {
          image: field.getPicture(
            entity,
            'field_gift_media_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
            },
            'gifts_width_380',
          ),
          actionImage: field.getPicture(
            entity,
            'field_gift_action_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
            },
            'gifts_width_380',
          ),
          annotation: field.getTextValue(entity, 'field_gift_description'),
          shortDescription: field.getTextValue(entity, 'field_secondary_description'),
        };

        transformedEntity = {
          ...transformedEntity,
          ...otherFields,
        };

        result['corporateGifts'][transformedEntity.id] = transformedEntity;
        break;
      }

      case 'gift_free': {
        const otherFields = {
          automaticallyAddToBasket: field.getBooleanValue(entity, 'field_boolean'),
          image: field.getPicture(
            entity,
            'field_gift_media_image',
            {
              'min-xs': {
                '1x': 'gifts_width_380',
                '2x': 'gifts_width_660',
              },
              sm: {
                '1x': 'gifts_width_720',
              },
            },
            'gifts_width_380',
          ),
          amount: field.getPriceValue(entity, 'field_gift_free_amount'),
          threshold: field.getPriceValue(entity, 'field_gift_free_amount_threshold'),
          basketMessage: field.getTextValue(entity, 'field_gift_description'),
          enjoyMessage: field.getTextValue(entity, 'field_secondary_description'),
        };

        transformedEntity = {
          ...transformedEntity,
          ...otherFields,
        };
        result['freeGifts'][transformedEntity.id] = transformedEntity;
        break;
      }
    }
  });

  return result;
};

const categories = async (categories, globalSettings) => {
  const TransformBlocks = (await import('../transforms.blocks')).default;
  const transformBlocks = new TransformBlocks();
  const result = {};
  Object.keys(categories).forEach((id) => {
    const entity = categories[id];
    try {
      const blocks = field.getArrayValue(entity, 'field_body_blocks');
      const blocksBottom = field.getArrayValue(entity, 'field_body_blocks_bottom');

      result[id] = {
        id: field.getNumberValue(entity, 'tid'),
        url: entity.url,
        title: field.getTextValue(entity, 'name'),
        pageTitle: field.getTextValue(entity, 'field_title'),
        // Transform paragraphs on the backend into body blocks on the frontend.
        blocks: transformBlocks.transform(entity, blocks, globalSettings, {}),
        blocksBottom: transformBlocks.transform(entity, blocksBottom, globalSettings, {}),
        metaTags: entity.field_metatags,
        weight: field.getNumberValue(entity, 'weight'),
        highlightedColor: field.getTextValue(entity, 'field_highlighted_color'),
      };

      if (result[id].title === 'All gifts') {
        if (globalSettings.catalogUrl.url === globalSettings.frontPage.homepageLink.url) {
          result[id].url.url = '/';
        } else {
          result[id].url.url = globalSettings.catalogUrl.url;
        }
      }
      delete result[id].field_metatags;
      delete result[id].field_body_blocks;
      delete result[id].field_body_blocks_bottom;
    } catch (e) {
      debug(`Error during transform for Gifts Category page with id=${id}. Error: %O`, e);
    }
  });
  return result;
};

export default {
  pages,
  products,
  categories,
};
